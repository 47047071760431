.listing-application-page {
  min-height: 730px;
  .wrap {
    .page_block {
      padding: 40px 0;
      width: 100%;
      .form_block {
        width: 100%;
        min-height: 400px;
        padding: 30px;
        border-radius: 16px;
        border: 1px solid #76b91b;
        box-shadow: 0px 27px 65px 0px rgba(65, 62, 62, 0.08);

        .logo {
          height: 100px;
          @media (max-width: 480px) {
            height: 70px;
          }
        }
        .title {
          color: #14192d;
          font-size: 30px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-align: center;
          @media (max-width: 480px) {
            font-size: 24px;
          }
        }
        .row1 {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          grid-gap: 20px;
        }
        .row2 {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 40px;
          @media (max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
          }
        }
        .section-title {
          color: #3f444d;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }
        .input-field {
          .field-lbl {
            color: #3f444d;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
          }

          .txt {
            font-size: 18px;
            padding: 10px 12px;
            border-radius: 8px;
            border: 1px solid #76b91b;
            background: #fff;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            &:focus {
              border-color: #14192d;
            }
          }
        }
        .action {
          .btn-submit {
            width: 50%;
            color: #fff;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-decoration-line: underline;
            border-radius: 12px;
            padding: 12px 20px;
            @media (max-width: 480px) {
              width: 100%;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}
