.edit-profile {
  min-height: 730px;
  padding: 100px 0;
  .wrap {
    .page-hdr {
      width: 100%;
      .page-heading {
        font-family: "Sofia Pro";
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 72px;
        color: #000000;
      }
      .btn {
        padding: 8px 20px;
      }
    }
    .wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 80px;
      margin: 30px 0;
      width: 100%;
      height: 100%;
      .left {
        .data-row {
          margin-bottom: 18px;
          .row-tag {
            padding: 0;
            font-family: "Sofia Pro";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            // line-height: 24px;
          }
          .desc {
            padding: 0;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
          }
          .txt {
            margin-top: 6px;
            color: #8f8f8f;
            font-weight: 300;
            background: rgba(196, 196, 196, 0.25);
            border-radius: 8px;
            padding: 10px 10px;
            border: 2px solid transparent;
            @include anim;
            &:focus {
              border-color: #76b91b;
            }
          }
        }
        .twitter-box {
          margin: 20px 0;
          .le {
            .icon {
              margin-right: 12px;
              svg {
                height: 24px;
                width: 24px;
                path {
                  fill: #55acee;
                }
              }
            }
            .icon-name {
              font-weight: 500;
              font-size: 18px;
              line-height: 29px;
              color: #000000;
            }
          }
          .re {
            .btn {
              padding: 6px 18px;
              border-color: #2081e2;
              background: #2081e2;
            }
          }
        }
        .social-links {
          margin-bottom: 40px;
          width: 100%;
          .row-tag {
            padding: 0;
            font-family: "Sofia Pro";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            // line-height: 24px;
          }
          .input-box {
            margin-top: 6px;
            background: rgba(196, 196, 196, 0.25);
            border: 2px solid transparent;
            @include anim;
            padding: 10px 10px;
            border-radius: 8px;
            .icon {
              svg {
                height: 18px;
                width: 18px;
              }
            }
            .txt {
              width: 100%;
              padding: 0 12px;
              color: #8f8f8f;
              font-weight: 300;
            }
            &:focus-within {
              border-color: #76b91b;
            }
          }
        }
        .update-btn {
          margin-top: 16px;
        }
      }
      .right {
        .data-row {
          margin-bottom: 40px;
          .row-tag {
            padding: 0;
            font-family: "Sofia Pro";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
          }
          .select-img {
            // flex: 1;
            margin-top: 12px;
            justify-content: start;
            .round {
              border-radius: 50% !important;
              width: 130px !important;
              height: 130px !important;
            }
            .img-box-c {
              width: 80% !important;
              height: 200px !important;
              @include media(">=phone", "<=480px") {
                width: 100% !important;
                height: 140px !important;
              }
            }
            .img-box {
              width: 260px;
              height: 150px;
              cursor: pointer;
              // padding: 14px;
              border-radius: 4px;
              @include media(">=phone", "<=480px") {
                width: 200px;
                height: 120px;
              }
              &.bdr {
                border: 4px dashed #8f8f8f;
              }
              .img {
                height: 100%;
                width: 100%;
                border-radius: 4px;
                object-fit: cover;
              }
              .icon {
                height: 60px;
              }
              svg {
                width: 100px;
                height: 40px;
              }
              .select-file {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
