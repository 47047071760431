@import "./Colors.scss";
@import "./Props.scss";
@import "./media.scss";
@import "./Swap.scss";
@import "./exchange-page.scss";
@import "./bySell.scss";
@import "../Pages/EditProfile/editProfile.scss";

@import url("http://fonts.cdnfonts.com/css/sofia-pro");

// @font-face {
//   font-family: "Dominican";
//   font-weight: normal;
//   src: local("Dominican"),
//     url("../assets/fonts/DOMII___.TTF") format("truetype");
// }

.font,
body {
  font-family: "Sofia Pro", sans-serif;
}
.font-b {
  font-family: "Heebo", sans-serif;
}

@mixin bgImage($radius: 50%) {
  height: 100%;
  width: 100%;
  border-radius: $radius;
  background-color: #e2e2e2;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

@mixin dropDownAnim($duration: 0.3s) {
  animation-name: drop;
  animation-duration: $duration;
  transform-origin: top;
}

@keyframes drop {
  0% {
    transform: scaleY(0.5);
    opacity: 0;
  }
}

.wrapWidth {
  width: 1200px;
  margin: 0 auto;
  @include media("<=xlarge") {
    width: 1200px;
    margin: 0 auto;
  }
  @include media("<=large") {
    width: 100%;
    padding: 0px 3%;
  }
  @include media("<=desktop") {
    width: 100%;
    padding: 0px 3%;
  }
  @include media("<=tablet") {
    // width: 540px;
    padding: 0px 3%;
  }
  @include media(">=phone", "<=480px") {
    padding: 0px 5%;
  }
}

.wrapper {
  // width: 1140px;
  height: 457px;
  width: 100%;
  // margin: 0 auto;
  @include media("<=xlarge") {
    width: 920px;
    height: 450px;
    margin: 0 auto;
  }
  @include media("<=large") {
    // width: 100%;
    padding: 0px 2%;
  }
  @include media("<=tablet") {
    // width: 100% !important;
    padding: 0px 3%;
    width: 720px;
  }
  @include media(">=phone", "<=480px") {
    padding: 0px 5%;
    width: 360px;
    height: 450px;
  }
}

// Slick Slider
.slick-slider {
  width: 100% !important;
}
.slick-next:before,
.slick-prev:before {
  font-size: 18px !important;
  font-weight: bold !important;
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev:before {
  content: "\ee06";
}

// .slick-prev:before {
//   content: "<" !important;
//   margin-left: 40px !important;
//   color: red !important;
//   font-size: 60px !important;
// }
// .slick-next:before {
//   content: ">" !important;
//   margin-right: 40px !important;
//   color: red !important;
//   font-size: 60px !important;
// }
.slick-prev,
.slick-next {
  background: linear-gradient(92.51deg, #3461ff 3.3%, #8454eb 93.12%);

  // background: $themeColor !important;
  height: 40px;
  top: -6% !important;
  transform: none !important;
  background: #4b9a2a !important;
  color: #000 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 15px !important;
  border-radius: 100%;
}

.slick-prev {
  width: 80px;
  height: 80px;
  left: 1100px !important;
  z-index: 99;
  color: #000 !important;
  &:before {
    // content: "\ee05" !important;
  }
  @include media(">=phone", "<=480px") {
    // visibility: hidden;
    // top: -6% !important;
    left: 170px !important;
  }
}

.slick-next {
  right: 66px !important;
  color: #000 !important;
  z-index: 99;
  right: 12px !important;
  &:before {
    // content: "\ee06" !important;
  }
  @include media("<=phoneH") {
    width: 0px;
    height: 0px;
    &:before {
      font-size: 10px;
    }
  }
  @include media(">=phone", "<=480px") {
    // visibility: hidden;
    // top: -6% !important;
    // right: 12px !important;
  }
}

// Toggle
.toggle-btn {
  flex: 0.4;
  .btn {
    display: flex !important;
    border-radius: 50px;
    padding: 10px 22px !important;
    background: #1c1924;
    border-bottom: 2px solid #000;
    .circle {
      left: 2px;
      // margin-bottom: 1px;
      padding: 8px;
      background: #fff;
      border-radius: 50%;
    }
    &.on {
      background: $themeColor;
      .circle {
        left: 25px;
        // background: $themeColor !important;
      }
    }
    &:hover {
      background: #1c1924;
      border-bottom: 2px solid #000;
    }
  }
}

//Drop Down
.dropDown {
  width: 100%;
  .category {
    width: 100%;
    .cbox {
      background: rgba(196, 196, 196, 0.25);
      // border: 1px solid #4b9a2a;
      padding: 0 16px;
      min-height: 42px;
      border-radius: 5px;
      box-sizing: border-box;
      min-width: 180px;
      width: 100%;
      justify-content: space-between;
      // &:hover {
      //   border: 1px solid #fff;
      // }
      @include media(">=phone", "<=480px") {
        min-width: 150px;
      }
      .icon-arrow {
        svg {
          path {
            fill: #000;
          }
        }
      }
      .slt {
        overflow: hidden;
        .ico {
          cursor: pointer;
          color: #000000d9;
          height: 22px;
          display: flex;
          align-items: center;
        }
        .lbl {
          color: #000;
          padding: 0 10px;
        }
        .unit-name {
          .unit-eng {
            color: #000;
          }
        }
      }
      svg {
        path {
          fill: #000;
        }
      }
      .d-img {
        margin: 2px 10px;
        .img {
          height: 25px;
          width: 25px;
          padding: 2.5px;
          border-radius: 100%;
          border: 1px solid $themeColor;
        }
      }
      .edit-icon {
        margin: 2px 10px;
        padding: 4px;
        border-radius: 100%;
        border: 1px solid $themeColor;
      }
      .dropDown-icon {
        cursor: pointer;
      }
    }
  }
  .block {
    top: 45px;
    left: 0;
    right: 0;
    border-radius: 4px;
    border: 1px solid $themeColor;
    background: #eee;
    box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
    visibility: hidden;
    opacity: 0;
    z-index: 2;
    width: 100%;
    .manue {
      width: 100%;
      // height: 150px;
      overflow-y: auto;
      padding: 0px 0px;
      &::-webkit-scrollbar {
        width: 4px;
      }
      .slt {
        width: 100%;
        padding: 8px 16px;
        @include anim;
        &.active {
          background-color: $themeColor;
          color: #242424;
          .unit-name {
            .unit-eng {
              color: #242424;
            }
          }
        }
        .unit-name {
          .unit-eng {
            color: #000;
          }
        }
        .ico {
          justify-content: flex-start;
          margin-right: 8px;
          width: 25px;
          height: 25px;
        }
        .lbl {
          flex: 1;
          margin-left: 5px;
          justify-content: flex-start;
        }
        &:hover {
          // background-color: #000;
          cursor: pointer;
          .ico {
            color: $themeColor;
          }
          .lbl {
            color: $themeColor;
          }
        }
      }
    }
    &.show {
      display: flex;
      visibility: visible;
      opacity: 1;
      @include dropDownAnim;
    }
  }
}

//Radio Button
.radio-btn {
  top: -11px;
  height: 22px;
  width: 22px;
  background: #000403;
  min-width: 22px;
  border: 2px solid $themeColor;
  border-radius: 50%;
  margin-right: 12px;
  &::before {
    content: "";
    content: "";
    position: absolute;
    height: 14px;
    width: 14px;
    min-width: 14px;
    background: $themeColor;
    border-radius: 50%;
    left: 2px;
    top: 2px;
    visibility: hidden;
    opacity: 0;
    @include anim;
  }
  &.on {
    border-color: $themeColor;
    &:before {
      visibility: visible;
      opacity: 1;
    }
  }
}
// Header
.header-cmp {
  z-index: 999;
  background: transparent;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 90px;
  padding: 12px 0px;
  position: relative;
  background-color: #002244;
  .wrap {
    // padding: 0px 30px;
    @include media(">=phone", "<=991px") {
      flex-direction: column;
    }
    .hdr-left {
      flex: 0.3;
      .menu-icon {
        display: none;
      }
      .logo-img {
        /*height: 76px;*/
        width: 140px;
      }
      @include media("<=desktop") {
        .logo-img {
          width: 140px;
        }
      }
      @include media(">=phone", "<=991px") {
        width: 100%;
        justify-content: space-between;
        .logo-img {
          /*height: 36px;*/
        }
        @include media(">=phone", "<=991px") {
          .menu-icon {
            cursor: pointer;
            display: flex;
            align-items: center;
          }
        }
      }
    }
    .hdr-center {
      flex: 1;
      .nav-list {
        gap: 28px;
        .li-item {
          font-family: "Sofia Sans Condensed";
          font-style: normal;
          font-weight: 400;
          font-size: 22px;
          line-height: 18px;
          color: #ffffff !important;
          cursor: pointer;
          @include anim;
          &:hover {
            color: #76b91b;
          }
          &:last-child {
            margin-right: 0;
          }
          &.active {
            color: #76b91b !important;
          }
          .ico {
            margin-left: 5px;
            // &:hover {
            //   svg {
            //     path {
            //       fill: #76b91b;
            //     }
            //   }
            // }
          }
          @include media("<=xlarge") {
            font-size: 22px;
          }
          @include media("<=desktop") {
            font-size: 14px;
          }
        }
      }
      @media screen and (max-width: 991px) {
        display: none;
      }
    }
    .hdr-right {
      @include media(">=phone", "<=480px") {
        padding: 4px 0;
      }
      .token-selection {
        .dropDown {
          .category {
            .cbox {
              min-width: 220px;
              border-radius: 30px;
              border: 2px solid #a7d63f;
              background: rgba(217, 217, 217, 0.5);
              .slt {
                .unit-name {
                  .unit-eng {
                    color: #000;
                    text-align: center;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                  }
                }
              }
              .arrow-icon {
                height: 24px;
                width: 24px;
                border-radius: 50%;
                background: #fff;
                svg {
                  path {
                    // fill: transparent;
                    // stroke: transparent;
                  }
                }
              }
            }
          }
          .block {
            padding: 12px 0;
            border-radius: 16px;
            border: 1px solid #76b91b;
            background: #fff;
            box-shadow: 0px 27px 65px 0px rgba(65, 62, 62, 0.08);
            .manue {
              .slt {
                padding: 10px 20px;
                &:hover {
                  background: rgba(231, 232, 234, 0.3);
                }
              }
            }
          }
        }
      }
      .btn {
        border-radius: 30px;
        font-family: "Sofia Pro";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #ffffff;
        @include media("<=xlarge") {
          font-size: 14px;
          padding: 10px 12px;
        }
        @include media(">=phone", "<=480px") {
          padding: 6px 20px !important;
        }
      }
    }
  }
}

.filter-sec {
  padding: 18px 0;
  &.mt-100 {
    // margin-top: 100px;
  }
  .left-search {
    flex: 1.3;
    .search-box {
      width: 100%;
      padding: 8px;
      background: #f3f4f8;
      border-radius: 8px;
      .ico {
        padding: 0 8px;
      }
      .txt {
        width: 100%;
        background: #f3f4f8;
        font-family: "Sofia Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #8f8f8f;
        padding: 0 8px;
      }
    }
  }
  .right-tab {
    flex: 1;
    padding-left: 16px;
    @include media(">=phone", "<=480px") {
      display: none;
    }
    .tabs {
      flex: 1;
      justify-content: space-between;
      border-bottom: 1px solid #cfd7de;
      .tab-item {
        flex: 1;
        cursor: pointer;
        @include anim;
        border-bottom: 3px solid transparent;
        .lbl {
          padding: 0 30px;
          @include anim;
          border-bottom: 3px solid transparent;
          &:hover {
            // border-bottom: 3px solid #76b91b;
          }
          &.active {
            border-bottom: 3px solid #76b91b;
          }
          svg {
            margin-left: 5px;
            // fill {
            //   path {
            //     fill: #000;
            //   }
            // }
          }
        }
        &:last-child {
          border-bottom: none;
        }
        .btn {
          margin-bottom: 5px;
          padding: 5px 20px;
          border-radius: 30px;
        }
        &.active {
          border-bottom: 3px solid #76b91b;
        }
      }
    }
  }
}

.filter-tabs {
  padding-top: 8px;
  padding-bottom: 20px;
  justify-content: space-between;
  @include media(">=phone", "<=480px") {
    display: none;
  }
  .tab-item {
    flex: 1;
    cursor: pointer;
    .icon {
      width: 16px;
      height: 16px;
    }
    .tag {
      font-family: "Sofia Pro";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 33px;
      color: #000000;
      margin-left: 5px;
      @include media("<=desktop") {
        font-size: 12px;
      }
    }
  }
}

// Home Page
.home-p {
  /*  height: 100%;
  width: 100%;*/
  /*.home-sec {
    height: 100%;
    width: 100%;
    min-height: 730px;
    height: calc(100vh - 80px);
    background-image: url("../../public/images/header-nft-final-client.gif");
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: right !important;
    @include media(">=phone", "<=480px") {
      background-image: url("../../public/images/header-nft-final-client.gif");
      background-position: center !important;
    }
  }*/
  .container {
    min-height: 730px;
    .wrap {
    }
  }
}

.hot-bids {
  align-items: center;
  justify-content: center;
  height: 530px;
  .wraps {
    // padding: 60px 0;
    height: 500px;
    .p-hdr {
      font-family: "Sofia Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      // line-height: 56px;
      /* identical to box height */

      color: #000000;
    }
    .hot-bids-nft {
      padding: 12px 0;
      align-items: center;
      justify-content: center;
      height: 100%;
      @include media("<=tablet") {
        padding: 10px 0;
      }
      @include media(">=phone", "<=480px") {
        padding: 15px 0;
      }
      .blk {
        height: 550px;
        width: 100%;
        justify-content: center;
        overflow: hidden;
        @include media(">=phone", "<=480px") {
          height: 120%;
        }
        .swiper {
          height: 100%;
          width: 100%;
        }
        .slick-slider {
          height: 480px !important;
        }
        .slick-list {
          height: 100% !important;
          .slick-track {
            height: 100% !important;
          }
        }
        .card {
          margin-top: 30px;
          height: 350px;
          width: 100%;
          background: #ffffff;
          // box-shadow: 0px 27px 65px rgba(65, 62, 62, 0.08);
          border-radius: 16px;
          @include media(">=phone", "<=480px") {
            height: 360px;
            width: 100%;
          }
          .nft-img {
            flex: 1;
            .img {
              height: 194px;
              width: 100%;
              border-radius: 10px;
              border-radius: 16px;
            }
          }
          .meta {
            flex: 1;
            padding: 12px;
            .nft-name {
              justify-content: space-between;
              .lbl {
                font-family: "Sofia Pro";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 30px;
                color: #8f8f8f;
              }
              .icon {
                cursor: pointer;
              }
            }
            .nft-numb {
              margin-bottom: 6px;
            }
            .nft-rating {
              justify-content: space-between;
              .r-num {
                .lbl {
                  font-family: "Sofia Pro";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  color: #000000;
                }
                .ico {
                  margin-left: 5px;
                  .svg {
                    height: 18px;
                  }
                }
              }
              .r-like {
                .lbl {
                  font-family: "Sofia Pro";
                  font-style: normal;
                  font-weight: 300;
                  font-size: 15px;
                  color: #8f8f8f;
                  height: 20px;
                }
                .ico {
                  cursor: pointer;
                  .svg {
                  }
                }
              }
            }
            .btn {
              margin-top: 5px;
              color: #ffffff;
              font-weight: 400;
              border-radius: 30px;
              padding: 6px 12px;
              background: linear-gradient(48.98deg, rgba(167, 214, 63, 0.9) -34.08%, #39b54a 44.93%, #9fd340 126.52%);
              @include anim;
              &:hover {
                color: #333539;
              }
            }
          }
        }
      }
    }
  }
}

.live-autions {
  align-items: center;
  justify-content: center;
  height: 530px;
  .wraps {
    height: 500px;
    .p-hdr {
      font-family: "Sofia Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      color: #000000;
    }
    .live-autions-nft {
      padding: 12px 0;
      align-items: center;
      justify-content: center;
      height: 100%;
      @include media("<=tablet") {
        padding: 10px 0;
      }
      @include media(">=phone", "<=480px") {
        padding: 15px 0;
      }
      .blk {
        height: 550px;
        width: 100%;
        justify-content: center;
        overflow: hidden;
        @include media(">=phone", "<=480px") {
          height: 120%;
        }
        .swiper {
          width: 100%;
        }

        .slick-slider {
          height: 480px !important;
        }
        .slick-list {
          height: 100% !important;
          .slick-track {
            height: 100% !important;
          }
        }
        .card {
          margin-top: 30px;
          height: 330px;
          width: 100%;
          background: #ffffff;
          // box-shadow: 0px 27px 65px rgba(65, 62, 62, 0.08);
          border-radius: 16px;
          @include media(">=phone", "<=480px") {
            height: 360px;
            width: 100%;
          }
          .nft-img {
            flex: 1;
            .img {
              height: 194px;
              width: 100%;
              border-radius: 10px;
              border-radius: 16px;
            }
            .time-box {
              top: 180px;
              text-align: center;
              border: 2.5px solid #4b9a2a;
              padding: 5px 12px;
              background: #333539;
              border-radius: 8px;
              .lbl {
                font-size: 13px;
                align-items: center;
                text-align: center;
                margin-right: 10px;
                color: #ffffff;
              }
            }
          }
          .meta {
            flex: 1;
            padding: 12px;
            justify-content: space-between;
            .nft-name {
              justify-content: space-between;
              .lbl {
                font-family: "Sofia Pro";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 30px;
                color: #8f8f8f;
              }
              .icon {
                cursor: pointer;
              }
            }

            .nft-rating {
              // justify-content: space-between;
              .r-num {
                flex: 0.6;
                .lbl {
                  font-family: "Sofia Pro";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  color: #000000;
                }
              }
              .r-like {
                flex: 1;
                .lbl {
                  font-family: "Sofia Pro";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 16px;
                  color: #4b9a2a;
                }
              }
            }
            .btn {
              margin-top: 5px;
              color: #ffffff;
              font-weight: 400;
              border-radius: 30px;
              padding: 6px 12px;
              background: linear-gradient(48.98deg, rgba(167, 214, 63, 0.9) -34.08%, #39b54a 44.93%, #9fd340 126.52%);
              @include anim;
              &:hover {
                color: #333539;
              }
            }
          }
        }
      }
    }
  }
}

.explore-sec {
  align-items: center;
  justify-content: center;

  .wraps {
    .p-hdr {
      font-family: "Sofia Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      // line-height: 56px;
      /* identical to box height */

      color: #000000;
    }
    .explore-sec-nft {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 20px;
      margin-top: 25px;
      margin-bottom: 25px;
      height: 100%;
      @include media("<=tablet") {
        padding: 10px 0;
      }
      @include media(">=phone", "<=480px") {
        padding: 15px 0;
        margin: 0px;
        grid-template-columns: repeat(1, 1fr);
      }
      .card {
        margin-top: 30px;
        height: 350px;
        width: 100%;
        // width: 260px;
        background: #ffffff;
        box-shadow: 0px 27px 65px rgba(65, 62, 62, 0.08);
        border-radius: 16px;
        @include media(">=phone", "<=480px") {
          height: 360px;
          margin-top: 0px;
        }
        .nft-img {
          flex: 1;
          .img {
            height: 194px;
            width: 100%;
            object-fit: cover;
            border-radius: 10px;
            border-radius: 16px;
          }
        }
        .meta {
          flex: 1;
          padding: 12px;
          .nft-name {
            justify-content: space-between;
            .lbl {
              font-family: "Sofia Pro";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 30px;
              color: #8f8f8f;
            }
            .icon {
              cursor: pointer;
            }
          }
          .nft-numb {
            margin-bottom: 6px;
          }
          .nft-rating {
            justify-content: space-between;
            .r-num {
              .lbl {
                font-family: "Sofia Pro";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                color: #000000;
              }
              .ico {
                margin-left: 5px;
                .svg {
                  height: 18px;
                }
              }
            }
            .r-like {
              .lbl {
                font-family: "Sofia Pro";
                font-style: normal;
                font-weight: 300;
                font-size: 15px;
                color: #8f8f8f;
              }
              .ico {
                cursor: pointer;
                .svg {
                }
              }
            }
          }
          .btn {
            margin-top: 5px;
            color: #ffffff;
            font-weight: 400;
            border-radius: 30px;
            padding: 6px 12px;
            background: linear-gradient(48.98deg, rgba(167, 214, 63, 0.9) -34.08%, #39b54a 44.93%, #9fd340 126.52%);
            @include anim;
            &:hover {
              color: #333539;
            }
          }
        }
      }
    }
    .action {
      padding: 20px 0;
      .btn {
        width: 200px;
        background: none;
        color: #76b91b;
        font-family: "Sofia Pro";
        border: 1px solid #76b91b;
        border-radius: 30px;
      }
    }
  }
}

.explore-page {
  align-items: center;
  justify-content: center;
  .explore-filter {
    width: 100%;
    padding: 8px 6%;
    .filter-sec {
      width: 100%;
    }
    .filter-tabs {
      flex: 1;
      width: 100%;
      .tab-item {
        border-bottom: 1px solid #eee;
      }
    }
  }
  .wraps {
    .p-hdr {
      font-family: "Sofia Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      // line-height: 56px;
      /* identical to box height */

      color: #000000;
    }
    .explore-page-nft {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 20px;
      margin-top: 25px;
      margin-bottom: 25px;
      height: 100%;
      @include media("<=tablet") {
        padding: 10px 0;
        margin: 0;
        grid-template-columns: repeat(1, 1fr);
      }
      @include media(">=phone", "<=480px") {
        padding: 15px 0;
      }
      .card {
        margin-top: 30px;
        height: 350px;
        width: 100%;
        // width: 260px;
        background: #ffffff;
        box-shadow: 0px 27px 65px rgba(65, 62, 62, 0.08);
        border-radius: 16px;
        @include media(">=phone", "<=480px") {
          height: 360px;
          margin-top: 0;
        }
        .nft-img {
          flex: 1;
          .img {
            height: 194px;
            width: 100%;
            object-fit: cover;
            border-radius: 10px;
            border-radius: 16px;
          }
        }
        .meta {
          flex: 1;
          padding: 12px;
          .nft-name {
            justify-content: space-between;
            .lbl {
              font-family: "Sofia Pro";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 30px;
              color: #8f8f8f;
            }
            .icon {
              cursor: pointer;
            }
          }
          .nft-numb {
            margin-bottom: 6px;
          }
          .nft-rating {
            justify-content: space-between;
            .r-num {
              .lbl {
                font-family: "Sofia Pro";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                color: #000000;
              }
              .ico {
                margin-left: 5px;
                .svg {
                  height: 18px;
                }
              }
            }
            .r-like {
              .lbl {
                font-family: "Sofia Pro";
                font-style: normal;
                font-weight: 300;
                font-size: 15px;
                color: #8f8f8f;
              }
              .ico {
                cursor: pointer;
                .svg {
                }
              }
            }
          }
          .btn {
            margin-top: 5px;
            color: #ffffff;
            font-weight: 400;
            border-radius: 30px;
            padding: 6px 12px;
            background: linear-gradient(48.98deg, rgba(167, 214, 63, 0.9) -34.08%, #39b54a 44.93%, #9fd340 126.52%);
            @include anim;
            &:hover {
              color: #333539;
            }
          }
        }
      }
    }
    .action {
      padding: 20px 0;
      .btn {
        width: 200px;
        background: none;
        color: #76b91b;
        font-family: "Sofia Pro";
        border: 1px solid #76b91b;
        border-radius: 30px;
      }
    }
  }
}

.our-app {
  flex: 1;
  min-height: 500px;
  background: #f7f7f7;
  padding: 60px;
  .wrap {
    .pg-hdr {
      font-family: "Sofia Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 54px;
      line-height: 64px;
      color: #1f1f1f;
      @include media(">=phone", "<=480px") {
        text-align: center;
        font-size: 20px;
        line-height: 25px;
      }
    }
    .meta {
      padding-top: 20px;
      @include media(">=phone", "<=480px") {
        flex-direction: column;
      }
      .left {
        flex: 1;
        .img {
          height: 320px;
          @include media(">=phone", "<=480px") {
            height: 182px;
          }
        }
      }
      .right {
        flex: 1;
        width: 400px;
        @include media(">=phone", "<=480px") {
          width: 100%;
          align-items: center;
          justify-content: center;
        }
        .meta {
          width: 340px;
          .desc {
            @include media(">=phone", "<=991px") {
              text-align: center;
              font-size: 16px;
            }
          }
          .apps-link {
            align-items: start;
            @include media(">=phone", "<=480px") {
              align-items: center;
              justify-content: center;
            }
            .btn {
              margin-top: 30px;
              width: 220px;
              display: flex !important;
              border-radius: 30px;
              padding: 8px 6px !important;
              .logo {
                flex: 0.5;
                .logo-img {
                  /*height: 30px;*/
                }
              }
              .txt {
                flex: 1;
                align-items: Start;
                .lbl {
                  line-height: 1;
                  font-size: 13px;
                  font-weight: 300;
                  color: #ffffff;
                  @include media(">=phone", "<=991px") {
                    font-size: 16px;
                  }
                }
                .name {
                  color: #ffffff;
                }
              }
              &:last-child {
                color: #1f1f1f;
                background: none;
                border: 1px solid #76b91b;
                .txt {
                  flex: 1;
                  align-items: Start;
                  .lbl {
                    color: #1f1f1f;
                  }
                  .name {
                    color: #1f1f1f;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.nft-detail {
  min-height: 730px;
  .nft-detail-filter {
    width: 100%;
    padding: 8px 6%;
    .filter-sec {
      width: 100%;
    }
  }
  .wrap {
    margin-top: 20px;
    .container {
      @include media(">=phone", "<=480px") {
        flex-direction: column;
      }
      .left {
        flex: 1;
        .box {
          .img {
            height: 330px;
            width: 490px;
            border-radius: 6px;
          }
        }
        .property-box {
          width: 490px;
          margin: 36px 0;
          background: rgba(196, 196, 196, 0.25);
          border-radius: 12px;
          padding: 20px;
          @include media(">=phone", "<=480px") {
            width: 100%;
            padding: 12px;
          }
          .tag {
            font-family: "Sofia Pro";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
            color: rgba(0, 0, 0, 0.5);
          }
          .property-tag {
            flex-wrap: wrap;
            .bx {
              margin-top: 10px;
              margin-right: 14px;
              border: 1px solid #8f8f8f;
              border-radius: 12px;
              padding: 6px;
              .lbl {
                font-family: "Sofia Pro";
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 25px;
                color: #4b9a2a;
              }
              .lbl2 {
                font-family: "Sofia Pro";
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 18px;
                color: #000000;
              }
              .val {
                font-family: "Sofia Pro";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 19px;

                color: #8f8f8f;
              }
            }
          }
        }
        .detail-box {
          width: 490px;
          margin-bottom: 76px;
          background: rgba(196, 196, 196, 0.25);
          border-radius: 12px;
          padding: 20px;
          @include media(">=phone", "<=480px") {
            width: 100%;
            padding: 12px;
            margin-bottom: 33px;
          }
          .tag {
            font-family: "Sofia Pro";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
            color: rgba(0, 0, 0, 0.5);
            padding-bottom: 18px;
          }
          .blk {
            .item {
              padding-bottom: 14px;

              &:last-child {
                padding-bottom: 0;
              }
              .le {
                flex: 0.4;
                .lbl1 {
                  font-family: "Sofia Pro";
                  font-style: normal;
                  font-weight: 700;
                  font-size: 18px;
                  line-height: 24px;
                  /* identical to box height */
                  margin: 0 10px;
                  color: #000000;
                }
                .lbl {
                  font-family: "Sofia Pro";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 20px;
                  /* or 111% */
                  color: rgba(0, 0, 0, 0.5);
                }
                svg {
                  width: 32px;
                  height: 32px;
                }
              }
              .ri {
                flex: 1;
                padding-left: 20px;
                .lbl {
                  font-family: "Sofia Pro";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  color: #000000;
                }
              }
            }
          }
        }
      }
      .right {
        flex: 1;
        padding: 0 30px;
        @include media(">=phone", "<=480px") {
          padding: 0;
        }
        .exploded-box {
          .hdr-tag {
            font-family: "Sofia Pro";
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            line-height: 51px;
            color: #4b9a2a;
          }
          .num {
            font-family: "Sofia Pro";
            font-style: normal;
            font-weight: 600;
            font-size: 38px;
            line-height: 27px;
            color: #000000;
          }
          .sale-tag {
            font-family: "Sofia Pro";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 25px;
            /* identical to box height */
            margin-top: 10px;
            margin-bottom: 20px;
            color: #8f8f8f;
            .s-lbl {
            }
            .s-tag {
              margin-left: 10px;
              color: #000;
              font-weight: 600;
              font-size: 16px;
            }
          }
          .desc {
            font-family: "Heebo";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            font-feature-settings: "pnum" on, "lnum" on;
            color: #1f1f1f;
          }
          .collector-sec {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 20px;
            margin-top: 10px;
            @include media(">=phone", "<=480px") {
              grid-template-columns: repeat(1, 1fr);
            }
            .item {
              // margin: 10px 0;
              .lbl {
                font-family: "Sofia Pro";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: #000000;
              }
              .nft-img {
                padding-top: 20px;
                @include media(">=phone", "<=480px") {
                  padding-top: 4px;
                }
                .img {
                  object-fit: cover;
                  height: 55px;
                  width: 55px;
                  border-radius: 50%;
                }
                .nft-name {
                  margin-left: 20px;
                }
              }
              .social {
                margin-right: 18px;
                &:last-child {
                  margin-right: 0;
                  .lbl {
                    margin-top: 15px;
                  }
                }
                .icon {
                  height: 24px;
                }
                .numb {
                  font-family: "Sofia Pro";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 16px;
                  color: #8f8f8f;
                }
                .lbl {
                  font-size: 14px;
                }
              }
            }
          }
          .actions {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 20px;
            margin: 20px 0;
            .btn {
              font-weight: 600;
              font-size: 16px;
              border-radius: 30px;
              &:last-child {
                color: #4b9a2a;

                background: rgba(196, 196, 196, 0.3);
              }
              @include media(">=phone", "<=480px") {
                padding: 6px 8px;
                font-size: 14px;
              }
            }
          }
        }
        .history-box {
          // width: 490px;
          margin-bottom: 76px;
          background: rgba(196, 196, 196, 0.25);
          border-radius: 12px;
          padding: 20px;
          .tag {
            font-family: "Sofia Pro";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
            color: rgba(0, 0, 0, 0.5);
            padding-bottom: 18px;
          }
          .blk {
            .item {
              margin-bottom: 18px;
              .le {
                flex: 0.1;
                .img {
                  border-radius: 50%;
                  height: 36px;
                  width: 36px;
                  object-fit: cover;
                  @include media(">=phone", "<=480px") {
                    height: 26px;
                    width: 26px;
                  }
                }
              }
              .ri {
                flex: 1;
                padding-left: 20px;
                .lbl {
                  font-family: "Sofia Pro";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  color: #000000;
                }
                .tkn {
                  font-family: "Sofia Pro";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 16px;
                  color: #8f8f8f;
                }
              }
            }
          }
        }
      }
    }
  }
}

.wallet-connect {
  background-color: #ffffff;
  min-height: 585px;
  width: 373px;
  padding: 24px 15px;
  .wrap {
    width: 100%;
    height: 100%;
    .hdr {
      justify-content: space-between;
      .lbl {
        font-family: "Sofia Pro";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 22px;
        color: #333539;
      }
    }
    .desc {
      font-family: "Sofia Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #8f8f8f;
      padding-right: 20px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .action {
      flex: 1;
      justify-content: space-between;
      height: 100%;
      .avil-wallet {
        width: 100%;
        /*padding: 0px 20px;*/
        .btn {
          width: 100%;
          text-align: center;
          padding: 10px 20px;
          cursor: pointer;
          background: rgba(231, 232, 234, 0.3);
          border: 1px solid rgba(216, 219, 224, 0.5);
          border-radius: 8px;
          margin-bottom: 4px;
          .img {
            /* flex: 0.4;*/
            justify-content: flex-end;
            height: 30px;
          }
          .lbl {
            flex: 1;
            margin-left: 10px;
            font-family: "Sofia Pro";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 18px;
            text-align: left;
            color: #333539;
            small {
              color: #000;
              font-family: "Sofia Pro";
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              display: block;
              width: 100%;
            }
          }
        }
      }
      .new-wallet {
        padding: 0px 20px;
        .qt-lbl {
          margin-top: 100px;
          padding: 6px 0;
          font-family: "Sofia Pro";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #8f8f8f;
        }
        .btn {
          width: 100%;
          font-family: "Sofia Pro";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 29px;
          color: #ffffff;
          border-radius: 12px;
        }
      }
    }
  }
}

.place-bid {
  background-color: #ffffff;
  min-height: 600px;
  width: 435px;
  padding: 15px;
  @include media(">=phone", "<=480px") {
    width: 320px;
  }
  .wrap {
    padding: 0 30px;
    width: 100%;
    height: 100%;
    .hdr {
      .hdr-tag {
        font-family: "Sofia Pro";
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 42px;
        text-align: center;
        color: #000000;
      }
      .desc {
        padding: 15px 0;
        font-family: "Sofia Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        color: #8f8f8f;
      }
    }
    .select-bid {
      padding: 10px 0;
      .txt-lbl {
        font-family: "Sofia Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
        padding-bottom: 8px;
      }
      .txt-box {
        background: rgba(196, 196, 196, 0.25);
        border-radius: 8px;
        padding: 12px 8px;
        border: 1px solid #4b9a2a;
        .txt {
          width: 100%;
          font-family: "Sofia Pro";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #000000;
        }
        .lbl {
          font-family: "Sofia Pro";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #000000;
        }
      }
      .txt-desc {
        font-family: "Sofia Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #8f8f8f;
      }
    }
    .t-fee {
      .lbl {
        font-weight: 300;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
      }
      .val {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
      }
    }
    .t-bid {
      padding: 14px 0;
      .lbl {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
      }
      .val {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
      }
    }
    .offer-exp {
      .lbl {
        padding: 14px 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
      }
      .actions {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
        margin: 20px 0;
        .dropDown {
          .category {
            .cbox {
              min-width: 100px !important;
            }
          }
        }
        .time-box {
          width: 100%;
          height: 45px;
          border: 1px solid #4b9a2a;
          background: rgba(196, 196, 196, 0.25);
          border-radius: 8px;
          padding: 0 10px;
          .img {
            height: 24px;
          }
          .time {
            width: 100%;
            flex: 1;
            padding-left: 5px;
          }
        }
      }
    }
    .btn-bid {
      padding-top: 50px;
      .btn {
        width: 200px;
        border-radius: 8px;
        font-weight: 600;
        font-size: 20px;
      }
    }
  }
}

.create-nft-page {
  min-height: 730px;
  .wrap {
    .pg-hdr {
      padding: 14px 0;
      .back-btn {
        cursor: pointer;
        .lbl {
          margin-left: 6px;
          font-family: "Heebo";
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 28px;
        }
      }
    }
    .meta {
      padding-bottom: 20px;
      .pg-tag {
        font-family: "Sofia Pro";
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 42px;
        text-align: center;
        color: #000000;
      }
      .desc {
        text-align: center;
        font-family: "Sofia Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .boxs {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
      @include media(">=phone", "<=480px") {
        grid-template-columns: repeat(1, 1fr);
      }
      .box {
        background: rgba(196, 196, 196, 0.25);
        border-radius: 16px;
        border: 1px solid #39b54a;
        height: 350px;
        @include media(">=phone", "<=480px") {
          height: 250px;
          &:last-child {
            margin-bottom: 30px;
          }
        }
        .img {
          height: 80px;
        }
        .lbl {
          margin-top: 6px;
          font-family: "Sofia Pro";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: #000000;
          text-align: center;
        }
      }
    }
  }
}

.single-nft {
  min-height: 730px;
  .wrap {
    .pg-hdr {
      width: 100%;
      padding: 14px 0;
      .back-btn {
        cursor: pointer;
        .lbl {
          margin-left: 6px;
          font-family: "Heebo";
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 28px;
        }
      }
    }
    .meta {
      width: 820px;
      text-align: center;
      align-items: center;
      @include media(">=phone", "<=480px") {
        width: 100%;
        padding: 0 3%;
      }
      .pg-tag {
        font-family: "Sofia Pro";
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 42px;
        text-align: center;
        color: #000000;
      }
      .blk {
        width: 100%;
        .star {
          color: #f01e01;
        }
        .req-lbl {
          margin-top: 20px;
          margin-bottom: 12px;
        }
        .row {
          text-align: start;
          padding-bottom: 30px;
          .txt-area {
            color: #8f8f8f;
            border-radius: 8px;
            background: rgba(196, 196, 196, 0.25);
            padding: 12px;
            height: 100px;
            margin-right: 10px;
          }
          .add-more {
            .btn {
              color: #000000;
              padding: 10px;
              border-radius: 8px;
              background: rgba(196, 196, 196, 0.25);
              .btn-lbl {
                svg {
                  path {
                    stroke: #000000;
                  }
                }
              }
            }
          }
          .r-lbl {
            .lbl-1 {
              font-family: "Sofia Pro";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
            }
            .lbl-2 {
              font-family: "Sofia Pro";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: #8f8f8f;
            }
          }
          .select-img {
            // flex: 1;
            margin-top: 12px;
            justify-content: start;
            .img-box {
              width: 260px;
              height: 150px;
              cursor: pointer;
              // padding: 14px;
              border-radius: 4px;
              @include media(">=phone", "<=480px") {
                width: 200px;
                height: 120px;
              }
              &.bdr {
                border: 4px dashed #8f8f8f;
              }
              .img {
                height: 100%;
                width: 100%;
                border-radius: 4px;
                object-fit: cover;
              }
              .icon {
                height: 60px;
              }
              svg {
                width: 100px;
                height: 40px;
              }
              .select-file {
                display: none;
              }
            }
          }
          .txt {
            margin-top: 12px;
            color: #8f8f8f;
            font-weight: 300;
            background: rgba(196, 196, 196, 0.25);
            border-radius: 8px;
            padding: 10px 10px;
          }
          .dropDown {
            width: 50%;
            margin-top: 12px;
            @include media(">=phone", "<=480px") {
              width: 100%;
            }
            .unit-eng {
              color: #8f8f8f;
            }
          }
          .input-boxs {
            width: 50%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 20px;
            @include media(">=phone", "<=480px") {
              width: 100%;
              grid-template-columns: repeat(1, 1fr);
            }
          }
          .h100 {
            height: 100px;
          }
          .left {
            flex: 0.6;
            @include media(">=phone", "<=480px") {
              flex: 1;
            }
            .lbl1 {
              font-family: "Sofia Pro";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              padding-bottom: 10px;
            }
            .lbl2 {
              font-size: 14px;
            }
          }
        }
      }
      .action {
        padding: 50px 0;
        .btn {
          width: 300px;
          border-radius: 30px;
        }
      }
    }
  }
}

.collection-nft {
  min-height: 730px;
  .wrap {
    .pg-hdr {
      width: 100%;
      padding: 14px 0;
      .back-btn {
        cursor: pointer;
        .lbl {
          margin-left: 6px;
          font-family: "Heebo";
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 28px;
        }
      }
    }
    .meta {
      width: 820px;
      text-align: center;
      align-items: center;
      @include media(">=phone", "<=480px") {
        width: 100%;
        padding: 0 3%;
      }
      .pg-tag {
        font-family: "Sofia Pro";
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 42px;
        text-align: center;
        color: #000000;
      }
      .blk {
        width: 100%;
        .star {
          color: #f01e01;
        }
        .req-lbl {
          margin-top: 20px;
          margin-bottom: 12px;
        }
        .row {
          text-align: start;
          padding-bottom: 30px;
          .r-lbl {
            .lbl-1 {
              font-family: "Sofia Pro";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
            }
            .lbl-2 {
              font-family: "Sofia Pro";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: #8f8f8f;
            }
          }
          .select-img {
            // flex: 1;
            margin-top: 12px;
            justify-content: start;
            .round {
              border-radius: 50% !important;
              width: 130px !important;
              height: 130px !important;
            }
            .img-box-c {
              width: 80% !important;
              height: 200px !important;
              @include media(">=phone", "<=480px") {
                width: 100% !important;
                height: 140px !important;
              }
            }
            .img-box {
              width: 260px;
              height: 150px;
              cursor: pointer;
              // padding: 14px;
              border-radius: 4px;
              @include media(">=phone", "<=480px") {
                width: 200px;
                height: 120px;
              }
              &.bdr {
                border: 4px dashed #8f8f8f;
              }
              .img {
                height: 100%;
                width: 100%;
                border-radius: 4px;
                object-fit: cover;
              }
              .icon {
                height: 60px;
              }
              svg {
                width: 100px;
                height: 40px;
              }
              .select-file {
                display: none;
              }
            }
          }
          .txt {
            margin-top: 12px;
            color: #8f8f8f;
            font-weight: 300;
            background: rgba(196, 196, 196, 0.25);
            border-radius: 8px;
            padding: 10px 10px;
            @include media(">=phone", "<=480px") {
              width: 100%;
            }
          }
          .dropDown {
            width: 50%;
            margin-top: 12px;
            @include media(">=phone", "<=480px") {
              width: 100%;
            }
            .unit-eng {
              color: #8f8f8f;
            }
          }
          .input-boxs {
            width: 50%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 20px;
          }
          .h100 {
            height: 100px;
          }
          .left {
            flex: 0.6;
            @include media(">=phone", "<=480px") {
              flex: 1;
            }
            .lbl1 {
              font-family: "Sofia Pro";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              padding-bottom: 10px;
            }
            .lbl2 {
              font-size: 14px;
            }
          }
          .search-box {
            margin-top: 12px;
            color: #8f8f8f;
            font-weight: 300;
            background: rgba(196, 196, 196, 0.25);
            border-radius: 8px;
            padding: 10px 10px;
            .ico {
              svg {
                height: 20px;
              }
            }
            .txt-search {
              width: 100%;
            }
          }
        }
      }
      .action {
        padding: 50px 0;
        .btn {
          width: 300px;
          border-radius: 30px;
        }
      }
    }
  }
}

.profile-page {
  min-height: 730px;
  .profile-page-filter {
    width: 100%;
    padding: 8px 6%;
    .filter-sec {
      width: 100%;
    }
  }
  .wrap {
    .user-info {
      position: relative;
      align-items: center;
      justify-content: end;
      .banner-img {
        height: 345px;
        width: 100%;
        border-radius: 8px;
        object-fit: cover;
        @include media(">=phone", "<=480px") {
          height: 150px;
        }
      }
      .user-p {
        position: absolute;
        bottom: -124px;
        .user-img-sec {
          .user-img {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 50%;
            border: 3px solid #000;
          }
          .edit-icon {
            background: $themeColor;
            padding: 6px;
            border-radius: 50%;
            border: 1px solid #fff;
            cursor: pointer;
            right: 0;
            bottom: 0px;
            svg {
              height: 20px;
              width: 20px;
              path {
              }
            }
          }
        }
        .user-name {
          font-family: "Sofia Pro";
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 56px;
          /* identical to box height */

          text-align: center;

          color: #1f1f1f;
        }
        .user-token {
          .icon-token {
          }
          .icon-copy {
          }
          .lbl {
            padding: 0 10px;
            font-family: "Heebo";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            color: #8f8f8f;
          }
        }
      }
    }
    .profile-tabs {
      margin-top: 140px;
      // padding: 10px 0;
      // margin-bottom: 15px;
      border-bottom: 1px solid #eee;

      .tab-item {
        font-family: "Sofia Pro";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        text-align: center;
        color: #000000;
        // padding-bottom: 4px;
        flex: 1;
        align-items: center;
        justify-content: center;
        @include media(">=phone", "<=480px") {
          font-size: 12px;
        }
        @include anim;
        .lbl {
          @include anim;
          cursor: pointer;
          border-bottom: 3px solid transparent;
          &.active {
            color: #8f8f8f;
            border-bottom: 3px solid rgba(167, 214, 63, 0.9);
          }
        }
      }
    }
    .search-box {
      margin: 25px 0;
      width: 100%;
      padding: 8px;
      background: #f3f4f8;
      border-radius: 8px;
      .ico {
        padding: 0 8px;
      }
      .txt {
        width: 100%;
        background: #f3f4f8;
        font-family: "Sofia Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #8f8f8f;
        padding: 0 8px;
      }
    }
    .filter-tabs {
      padding-bottom: 20px;
    }
    .profile-page-nft {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 20px;
      // margin-top: 25px;
      margin-bottom: 25px;
      height: 100%;
      @include media("<=tablet") {
        padding: 10px 0;
      }
      @include media(">=phone", "<=480px") {
        padding: 15px 0;
        grid-template-columns: repeat(2, 1fr);
      }
      .card {
        // margin-top: 30px;
        height: 350px;
        width: 100%;
        // width: 260px;
        background: #ffffff;
        box-shadow: 0px 27px 65px rgba(65, 62, 62, 0.08);
        border-radius: 16px;
        @include media(">=phone", "<=480px") {
          height: 360px;
        }
        .nft-img {
          flex: 1;
          .img {
            height: 194px;
            width: 100%;
            object-fit: cover;
            border-radius: 10px;
            border-radius: 16px;
          }
        }
        .meta {
          flex: 1;
          padding: 12px;
          .nft-name {
            justify-content: space-between;
            .lbl {
              font-family: "Sofia Pro";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 30px;
              color: #8f8f8f;
            }
            .icon {
              cursor: pointer;
            }
          }
          .nft-numb {
            margin-bottom: 6px;
          }
          .nft-rating {
            justify-content: space-between;
            .r-num {
              .lbl {
                font-family: "Sofia Pro";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                color: #000000;
              }
              .ico {
                margin-left: 5px;
                .svg {
                  height: 18px;
                }
              }
            }
            .r-like {
              .lbl {
                font-family: "Sofia Pro";
                font-style: normal;
                font-weight: 300;
                font-size: 15px;
                color: #8f8f8f;
              }
              .ico {
                cursor: pointer;
                .svg {
                }
                &.fav {
                  .svg {
                    path {
                      fill: #f01e01;
                    }
                  }
                }
              }
            }
          }
          .btn {
            margin-top: 5px;
            color: #ffffff;
            font-weight: 400;
            border-radius: 30px;
            padding: 6px 12px;
            background: linear-gradient(48.98deg, rgba(167, 214, 63, 0.9) -34.08%, #39b54a 44.93%, #9fd340 126.52%);
            @include anim;
            &:hover {
              color: #333539;
            }
          }
        }
      }
    }
  }
}

.my-nfts-page {
  min-height: 730px;
  .profile-page-filter {
    width: 100%;
    padding: 8px 6%;
    .filter-sec {
      width: 100%;
    }
  }
  .wrap {
    .nft-info {
      position: relative;
      align-items: center;
      justify-content: end;
      .banner-img {
        height: 345px;
        width: 100%;
        border-radius: 8px;
        object-fit: cover;
        @include media(">=phone", "<=480px") {
          height: 150px;
        }
      }
      .user-p {
        position: absolute;
        bottom: -124px;
        .user-img {
          width: 100px;
          height: 100px;
          object-fit: cover;
          border-radius: 50%;
          border: 3px solid #000;
        }
        .user-name {
          font-family: "Sofia Pro";
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 56px;
          /* identical to box height */

          text-align: center;

          color: #1f1f1f;
        }
        .user-token {
          .lbl {
            padding: 0 10px;
            font-family: "Heebo";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 28px;
            color: #8f8f8f;
          }
          .tkn {
            font-size: 18px;
            color: #000;
          }
        }
      }
    }
    .report-grid {
      margin-top: 150px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 20px;
      border: 1px solid #8f8f8f;
      border-radius: 12px;
      width: 70%;
      display: none;
      @include media(">=phone", "<=480px") {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0px;
        width: 100%;
      }
      .report-card {
        border-right: 1px solid #8f8f8f;
        height: 120px;
        padding: 12px;
        &:last-child {
          border-right: none;
        }
        @include media(">=phone", "<=480px") {
          &:nth-child(2) {
            border-right: none;
          }
          &:nth-child(3) {
            border-bottom: none;
          }
          &:nth-child(4) {
            border-bottom: none;
          }
          border-bottom: 1px solid #8f8f8f;
        }
        ._numbs {
          .ico {
            margin-right: 5px;
            svg {
              width: 30px;
              height: 30px;
              @include media(">=phone", "<=480px") {
                width: 24px;
                height: 24px;
              }
            }
          }
          .numb-lbl {
            font-family: "Sofia Pro";
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 38px;
            text-align: center;
            color: #000000;
            margin-bottom: 4px;
            @include media(">=phone", "<=480px") {
              font-size: 26px;
            }
          }
        }
        .report-lbl {
          font-family: "Sofia Pro";
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
          text-align: center;
          color: #8f8f8f;
          @include media(">=phone", "<=480px") {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
    .profile-tabs {
      margin-top: 150px;
      // padding: 10px 0;
      // margin-bottom: 15px;
      border-bottom: 1px solid #eee;
      .tab-item {
        font-family: "Sofia Pro";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        text-align: center;
        color: #000000;
        // padding-bottom: 4px;
        margin-right: 20px;
        align-items: center;
        justify-content: center;
        &:last-child {
          margin-right: 0;
        }
        @include anim;
        .lbl {
          @include anim;
          cursor: pointer;
          border-bottom: 3px solid transparent;
          &.active {
            color: #8f8f8f;
            border-bottom: 3px solid rgba(167, 214, 63, 0.9);
          }
        }
      }
    }
    .search-box {
      margin: 25px 0;
      width: 100%;
      padding: 8px;
      background: #f3f4f8;
      border-radius: 8px;
      .ico {
        padding: 0 8px;
      }
      .txt {
        width: 100%;
        background: #f3f4f8;
        font-family: "Sofia Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #8f8f8f;
        padding: 0 8px;
      }
    }
    .filter-tabs {
      padding-bottom: 20px;
    }
    .profile-page-nft {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 20px;
      // margin-top: 25px;
      margin-bottom: 25px;
      height: 100%;
      @include media("<=tablet") {
        padding: 10px 0;
        grid-gap: 10px;
      }
      @include media(">=phone", "<=480px") {
        padding: 15px 0;
        grid-template-columns: repeat(2, 1fr);
      }
      .card {
        // margin-top: 30px;
        height: 350px;
        width: 100%;
        // width: 260px;
        background: #ffffff;
        box-shadow: 0px 27px 65px rgba(65, 62, 62, 0.08);
        border-radius: 16px;
        @include media(">=phone", "<=480px") {
          height: 360px;
        }
        .nft-img {
          flex: 1;
          .img {
            height: 194px;
            width: 100%;
            object-fit: cover;
            border-radius: 10px;
            border-radius: 16px;
          }
        }
        .meta {
          flex: 1;
          padding: 12px;
          .nft-name {
            justify-content: space-between;
            .lbl {
              font-family: "Sofia Pro";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 30px;
              color: #8f8f8f;
            }
            .r-num {
              .lbl {
                font-family: "Sofia Pro";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                color: #000000;
              }
              .ico {
                margin-left: 5px;
              }
            }
          }
          .nft-numb {
            margin-bottom: 6px;
          }
          .nft-rating {
            justify-content: space-between;
            .r-num {
              .lbl {
                font-family: "Sofia Pro";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                color: #000000;
              }
              .buy-lbl {
                color: #39b54a;
                font-weight: 600;
              }
              .ico {
                margin-left: 5px;
                .svg {
                  height: 18px;
                }
              }
            }
            .r-like {
              .lbl {
                font-family: "Sofia Pro";
                font-style: normal;
                font-weight: 300;
                font-size: 15px;
                color: #8f8f8f;
              }
              .ico {
                margin-bottom: 4px;
                cursor: pointer;
                .svg {
                }
              }
            }
          }
          .btn {
            margin-top: 5px;
            color: #ffffff;
            font-weight: 400;
            border-radius: 30px;
            padding: 6px 12px;
            background: linear-gradient(48.98deg, rgba(167, 214, 63, 0.9) -34.08%, #39b54a 44.93%, #9fd340 126.52%);
            @include anim;
            &:hover {
              color: #333539;
            }
          }
        }
      }
    }
  }
}
.u-a {
  margin: 20px 0;
  overflow: auto;
  &::-webkit-scrollbar {
    // width: 4px;
    height: 6px;
  }
  @include media(">=phone", "<=480px") {
  }
  .user-activity {
    padding: 14px 0;
    width: 100%;
    @include media(">=phone", "<=480px") {
      width: 1024px;
    }
    .tbl {
      @include media(">=phone", "<=480px") {
        width: 1024px;
      }
      .row {
        padding: 10px 0;

        &:first-child {
          border-top: 3px solid rgba(143, 143, 143, 0.3);
          border-bottom: 3px solid rgba(143, 143, 143, 0.3);
        }
        .row-item {
          font-family: "Sofia Pro";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 30px;
          color: #000000;
          flex: 1;
          &:nth-child(2) {
            flex: 2;
          }
          .img {
            height: 50px;
            width: 50px;
            border-radius: 8px;
          }
          .nft-names {
            margin: 0 10px;
            .lbl1 {
              line-height: 1;
              font-size: 14px;
            }
            .lbl2 {
              line-height: 2;
              font-size: 13px;
              color: #8f8f8f;
            }
          }
        }
      }
    }
  }
}

.footer-pg {
  height: 300px;
  padding: 60px 30px;
  background: #002244;
  @include media(">=phone", "<=480px") {
    height: 100%;
    padding: 30px 0px;
  }
  .wrap {
    @include media(">=phone", "<=480px") {
      flex-direction: column;
    }
    .left {
      flex: 0.5;
      @include media(">=phone", "<=480px") {
        flex: 1;
        align-items: center;
        justify-content: center;
      }
      .logo-img {
        width: 200px;
        @include media(">=phone", "<=480px") {
          width: 100px;
        }
      }
      .social {
        justify-content: center;
        width: 200px;
        gap: 14px;
        .icon {
          height: 24px;
          width: 24px;
          padding: 4px;
          cursor: pointer;
          border-radius: 50%;
          background-color: #1da1f2;
        }
      }
    }
    .right {
      flex: 1;
      @include media(">=phone", "<=480px") {
        flex-direction: column;
        text-align: center;
      }
      .items {
        flex: 1;
        @include media(">=phone", "<=480px") {
          padding: 15px 0;
        }
        .tag {
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 24px;
          margin-bottom: 16px;
          color: #ffffff;
        }
        .lbl {
          font-style: normal;
          font-weight: 200;
          font-size: 18px;
          line-height: 27px;
          color: #8f8f8f !important;
          text-decoration: underline;
        }
      }
    }
  }
}

// Side Bar
.sidebar-s {
  background: rgba(0, 0, 0, 0.3);
  z-index: 99999;
  height: 100%;
  inset: 0;
  overflow: hidden;
  .side-block {
    width: 280px;
    background: #002244;
    height: 100%;
    overflow-y: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    transition: all 0.3s cubic-bezier(0.9, 0.32, 0.46, 1.18) 0s;
    &::-webkit-scrollbar {
      width: 5px;
    }
    @include media(">=phone", "<=480px") {
      width: 70%;
    }
    &.show {
      right: 0;
      .body {
        overflow-y: hidden;
      }
    }
    &.hide {
      right: -450px;
    }
    .hdr {
      flex: 0.2;
      padding: 14px;
      width: 100%;
      justify-content: flex-end;
      background-color: $themeColor;
      .icon-close {
        height: 20px;
        cursor: pointer;
        svg {
          height: 30px;
          width: 30px;

          path {
            stroke: #fff;
            fill: #fff;
          }
        }
      }
    }
    .items {
      padding: 0 18px;
      .list-item {
        width: 100%;
        padding-bottom: 12px;
        margin-bottom: 10px;
        .li {
          width: 70%;
          cursor: pointer;
          font-weight: 300;
          font-size: 16px;
          line-height: 16px;
          border-bottom: 2px solid transparent;
          @include anim;
          &:hover {
            // border-bottom: 2px solid #fb5f38;
            color: #26c265 !important;
          }
          @include media("<=tablet") {
            display: flex;
            // padding-bottom: 10px;
            // margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.complete-checkout {
  height: 300px;
  width: 400px;
  padding: 12px;
  background-color: #fff;
  @include media(">=phone", "<=480px") {
    width: 300px;
    height: 100%;
  }
  .wrap {
    .checkout-hdr {
      .icon {
        svg {
          cursor: pointer;
        }
      }
      .checkout-tag {
        .lbl {
          color: #000000;
          font-weight: 600;
          font-size: 24px;
          line-height: 42px;
        }
      }
    }
    .min-tag {
      padding: 5px 25px;
      color: #000000;
      font-family: "Sofia Pro";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
    }
    .about-nft {
      padding: 10px 25px;
      border-top: 1px solid rgba(196, 196, 196, 0.25);
      border-bottom: 1px solid rgba(196, 196, 196, 0.25);
      @include media(">=phone", "<=480px") {
        flex-direction: column;
      }
      .left {
        flex: 1.5;
        .left-side {
          flex: 0.5;
          .img-s {
            padding-right: 10px;
            .img {
              width: 110px;
              border-radius: 6px;
            }
          }
        }
        .right-side {
          flex: 1;
          .type {
            font-family: "Sofia Pro";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            color: rgba(20, 25, 45, 0.75);
          }
          .name {
            font-family: "Sofia Pro";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            color: #1f1f1f;
            padding: 6px 0;
          }
          .fee {
            font-family: "Sofia Pro";
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 13px;
            color: #8f8f8f;
          }
        }
      }
      .right {
        flex: 1;
        @include media(">=phone", "<=480px") {
          align-items: end;
          padding: 20px 0px;
        }
        .xrp {
          padding-bottom: 6px;
          align-items: end;
          .icon {
            margin-right: 6px;
          }
          .xrp-tag {
            font-family: "Sofia Pro";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            color: #000000;
            text-align: end;
          }
        }
        .price {
          font-family: "Sofia Pro";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 14px;
          color: #8f8f8f;
          width: 100%;
          text-align: end;
        }
      }
    }
    .t-price {
      padding: 5px 25px;
      .lbl {
        font-family: "Sofia Pro";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }
    }
    .action {
      padding: 10px 0;
      .btn {
        width: 140px;
        color: #fff;
        font-family: "Sofia Pro";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 29px;
        border-radius: 8px;
      }
    }
  }
}

.create-new-item {
  min-height: 730px;
  .wrap {
    .pg-hdr {
      width: 100%;
      padding: 14px 0;
      .back-btn {
        cursor: pointer;
        .lbl {
          margin-left: 6px;
          font-family: "Heebo";
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 28px;
        }
      }
    }
    .meta {
      width: 850px;
      text-align: center;
      align-items: center;
      @include media(">=phone", "<=480px") {
        width: 100%;
        padding: 0 3%;
      }
      .nft-img {
        padding: 20px 0;
        .img {
          height: 215px;
          width: 330px;
          border-radius: 6px;
        }
      }
      .pg-tag {
        font-family: "Sofia Pro";
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 42px;
        text-align: center;
        color: #000000;
      }
      .req-nft-info {
        width: 100%;
        @include media(">=phone", "<=480px") {
          flex-direction: column;
        }
        .left {
          // flex: 1;
          @include media(">=phone", "<=480px") {
            padding: 20px 0;
          }
          .left-box {
            padding-right: 20px;
            padding: 12px;
            min-height: 250px;
            width: 380px;
            background: #f0f0f0;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 8px;
            @include media(">=phone", "<=480px") {
              background: transparent;
              box-shadow: none;
              padding: 10px 3%;
            }
            .left-tag {
              text-align: start;
              font-family: "Sofia Pro";
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 24px;
              color: #000000;
            }
            .boxs {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              grid-gap: 20px;
              padding: 20px 0;
              @include media(">=phone", "<=480px") {
                grid-gap: 10px;
              }
              .le-card {
                height: 142px;
                width: 100%;
                background: #e5e5e5;
                border-radius: 8px;
                padding: 8px;
                justify-content: space-around;
                cursor: pointer;
                @include anim;
                border: 2px solid transparent;

                .icon {
                }
                .card-lbl {
                  font-family: "Sofia Pro";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 24px;
                  color: #000000;
                }
                &:hover {
                  border: 2px solid #76b91b;
                }
                &.active {
                  border: 2px solid #76b91b;
                }
              }
            }
            .starting-date {
              text-align: start;
              .lbl1 {
                text-align: start;
              }
              .date-time {
                font-size: 12px;
                padding: 8px;
                border-radius: 6px;
                background: #e5e5e5;
              }
            }
            .row-left1 {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              grid-gap: 15px;
              padding: 10px 0;
              @include media(">=phone", "<=480px") {
                grid-template-columns: repeat(1, 1fr);
              }
            }
            .row-left2 {
              display: grid;
              grid-template-columns: repeat(1, 1fr);
              grid-gap: 15px;
              padding: 10px 0;
            }
          }
        }
        .right {
          flex: 1;
          height: 100%;
          width: 100%;
          .right-block {
            padding-left: 26px;
            width: 100%;
            @include media(">=phone", "<=480px") {
              padding-left: 0;
            }
            .row {
              width: 100%;
              margin-bottom: 26px;
              justify-content: space-between;
              .text-start {
                text-align: start !important;
              }
              .lbl1 {
                color: #000000;
                font-family: "Sofia Pro";
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 28px;
                margin-bottom: 8px;
                @include media(">=phone", "<=480px") {
                  font-size: 15px;
                }
              }
              .lbl2 {
                font-family: "Sofia Pro";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: #000000;
                @include media(">=phone", "<=480px") {
                  font-size: 13px;
                }
              }
              .date-time {
                padding: 8px;
                border-radius: 6px;
                background: #f0f0f0;
                box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
              }
              .row-left {
                text-align: start;
              }
              .row-right {
                .lbl2 {
                  font-family: "Sofia Pro";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 24px;
                  color: #000000;
                }
                .toggle-btn {
                  .btn {
                    border-bottom: none;
                  }
                }
              }
              .price-box {
                background: #f0f0f0;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                .dropDown {
                  width: auto !important;
                  .category {
                    width: auto !important;
                    .cbox {
                      min-width: 120px !important;
                      background: #e5e5e5;
                      .slt {
                        width: 100%;
                        .unit-name {
                          width: 100%;
                          justify-content: space-between;
                          .unit-eng {
                            padding: 0 5px;
                          }
                        }
                      }
                    }
                  }
                }
                .txt {
                  width: 100%;
                  padding: 0 8px;
                }
              }
              .desc {
                margin-top: 5px;
                font-family: "Sofia Pro";
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                color: #8f8f8f;
                .des-numb {
                  padding: 0 5px;
                  font-size: 16px;
                  color: #000000;
                }
              }
            }
          }
        }
      }
      .action {
        padding: 50px 0;
        .btn {
          width: 300px;
          border-radius: 30px;
        }
      }
    }
  }
}

// swap modal
// swap modal
.swapTransModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: #f6f6f68a;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.swapModal-card {
  width: 350px;
  background-color: #fff;
  border-radius: 8px;
  padding: 15px 20px;

  @include media(">=phone", "<=480px") {
    width: 95%;
  }
}

// spinner
.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-text {
  color: #4db948;
}

// collection nft
.xrp-dropdown-img {
  width: 30px;
  margin-right: 15px;
}

// activity profile
.activity-img {
  width: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
.activities-table thead {
  border: 3px solid rgba(143, 143, 143, 0.3);
}
.activities-table tbody {
  border-bottom: 3px solid rgba(143, 143, 143, 0.3);
}
.activities-table th {
  font-weight: 600;
  text-align: center;
}
.activities-table tr {
  text-align: center;
}
.activities-table td {
  border-left: 3px solid rgba(143, 143, 143, 0.3);
}
.activities-table td:last-child {
  border-right: 3px solid rgba(143, 143, 143, 0.3);
}
.activities-table td,
.activities-table th {
  padding: 20px 10px;
}
.activity-td-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

// collection
.custom-url {
  margin-top: 12px;
  background-color: #c4c4c440;
  padding: 10px;
  padding-right: 0;
  border-radius: 8px 0 0 8px;
  color: #8f8f8f;
}
.custom-url-input {
  padding-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.verify-btn {
  margin-top: 12px;
  margin-left: 10px;
}
.copy-btn {
  background: #f1f1f1;
  color: #333;
  margin-top: 12px;
  margin-left: 10px;
}
.copy-btn:hover {
  background: #ccc;
}

.color-toggle .checkbox {
  opacity: 0;
  position: absolute;
}
.color-toggle .checkbox-label {
  background-color: #111;
  width: 50px;
  height: 26px;
  border-radius: 50px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.color-toggle .fa-moon {
  color: #f1c40f;
}
.color-toggle .fa-sun {
  color: #f39c12;
}
.color-toggle .ball {
  background-color: #fff;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}
.checkbox:checked + .checkbox-label .ball {
  transform: translateX(24px);
}

.checkbox:checked + .checkbox-label {
  background: #6ac445;
}
.color-toggle {
  background: #d5d2d2;
  position: absolute;
  z-index: 999;
  right: 10px;
  top: 26px;
  padding: 5px;
  border-radius: 40px;
  box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.3098039216);
  cursor: pointer;
}
@media (max-width: 1200px) {
  .header-cmp .wrapWidth {
    width: 90%;
  }
}
@media (max-width: 991px) {
  .header-cmp .wrap .hdr-right {
    display: none;
  }
}
@media (max-width: 768px) {
  .header-cmp .wrapWidth {
    width: 80%;
  }
}
@media (max-width: 575px) {
  .header-cmp .wrapWidth {
    width: 87%;
    margin-left: 0;
  }
}
/*************Dark-Mode css**************/
.dark-mode .color-toggle {
  background: #5ebb46;
}
.dark-mode .color-toggle svg path {
  fill: #fff;
}
.dark-mode .wallet-connect .wrap .desc.headss {
  color: #fff;
}
.dark-mode {
  background: #1f1f1f;
  color: #fff;
}
.dark-mode .Maintrading {
  background: #1f1f1f !important;
}
.dark-mode .tradetext,
.dark-mode .collection-nft .wrap .meta .pg-tag,
.dark-mode .single-nft .wrap .meta .pg-tag {
  color: #fff;
}
.dark-mode .hot-bids .swiper-wrapper {
  padding-bottom: 20px;
}
.dark-mode .tradetext1,
.dark-mode .create-nft-page .wrap .meta .pg-tag {
  color: #fff;
}
// .dark-mode  .mainexchangediv,
// .dark-mode .cryptodiv,
// .dark-mode .leveragediv,
// .dark-mode .aboutdiv {
//   background: #fff;
// }
.dark-mode .exchangetext {
  color: #fff;
}
.dark-mode .exchangediv {
  border: 1px solid #6ac445;
}
.dark-mode .my-nfts-page .wrap .profile-tabs .tab-item .lbl {
  color: #fff;
}
.nft-detail .wrap .container .right .exploded-box .collector-sec .item .nft-img .nft-name {
  color: #000;
}
.dark-mode .nft-detail .wrap .container .right .exploded-box .collector-sec .item .nft-img .nft-name {
  color: #fff;
}
.dark-mode .my-nfts-page .wrap .profile-page-nft .card {
  background: #1f1f1f;
  border: 1px solid rgba(128, 128, 128, 0.5215686275);
}
.dark-mode .my-nfts-page .wrap .profile-page-nft .card .icon svg path {
  fill: #fff;
}
.dark-mode .my-nfts-page .wrap .report-grid .report-card ._numbs .numb-lbl {
  color: #fff;
}
.dark-mode .Mainconduct {
  background: #1f1f1f;
  /* color: #fff; */
}
.dark-mode .conducttext {
  color: #fff;
}
.dark-mode .bittext {
  color: #fff;
}
.dark-mode .Mainaccount {
  background: #1f1f1f;
}
.dark-mode .Mainaccount .accinputdiv {
  background: #333539;
  border-color: #333539;
}
.dark-mode .footer-pg {
  background: #1f1f1f;
}
.dark-mode .exchange-page {
  background: #1f1f1f;
}
.dark-mode .exchange-page .wrap .content-block .left .le-top,
.dark-mode .exchange-page .wrap .content-block .left .le-btm,
.dark-mode .exchange-page .wrap .content-block .center .center-hdr,
.dark-mode .exchange-page .wrap .content-block .center .center-hdr .content-side .cnt_left,
.dark-mode .exchange-page .wrap .content-block .center .center-hdr .content-side .cnt_right .item .val,
.dark-mode .exchange-page .wrap .content-block .center .graph-sec,
.dark-mode .exchange-page .wrap .content-block .right,
.dark-mode .exchange-page .wrap .content-block .right .token-table .table-block .tbl-row .row-item,
.dark-mode .exchange-page .wrap .content-block .center .connection-sec,
.dark-mode .exchange-page .wrap .content-block .center .connection-sec .connects-tabs .tabs-item,
.dark-mode .exchange-page .wrap .orders-sec {
  background: #1f1f1f;
  color: #fff;
  border: 1px solid #80808085;
}
.dark-mode .exchange-page .wrap .content-block .left .le-top .token_tabs .item,
.dark-mode .exchange-page .wrap .content-block .left .le-btm .sec-tag,
.dark-mode .exchange-page .wrap .content-block .left .le-btm .token-table .table-block .tbl-row .row-item,
.dark-mode .exchange-page .wrap .orders-sec .tabs-sec .i-tab,
.dark-mode .exchange-page .wrap .orders-sec .table-block .tbl-sec .tbl-row .tbl-col {
  color: #fff;
}
.dark-mode .exchange-page .wrap .content-block .left .le-top .token-table .table-block .tbl-row .row-item,
.dark-mode .exchange-page .wrap .content-block .left .le-top .token-table .table-block .tbl-row .row-item .name1,
.dark-mode .wallet-connect .wrap .hdr .lbl,
.dark-mode .swap-page .wrap .swap-block .swap-tab .swap-item,
.dark-mode .swap-page .wrap .swap-block .swap-cards .card .card-hdr .token-info .about-token .lbl,
.dark-mode .filter-tabs .tab-item .tag,
.dark-mode .explore-page .wraps .p-hdr,
.dark-mode .hot-bids .wraps .hot-bids-nft .blk .card .meta .nft-numb,
.dark-mode .hot-bids .wraps .hot-bids-nft .blk .card .meta .nft-rating .r-num .lbl,
.dark-mode .hot-bids .wraps .p-hdr,
.dark-mode .live-autions .wraps .p-hdr,
.dark-mode .explore-sec .wraps .p-hdr {
  color: #fff;
}
.dark-mode .swap-page .wrap .swap-block .swap-tab {
  border-bottom: 2px solid #80808085;
}
.dark-mode .our-app {
  background: #1f1f1f;
}
.dark-mode .exchange-page .wrap .content-block .left .le-top .token-table .table-block .tbl-row:hover,
.dark-mode .exchange-page .wrap .content-block .left .le-btm .token-table .table-block .tbl-row:hover {
  background: #002244;
}
.dark-mode .our-app .wrap .pg-hdr,
.dark-mode .our-app .wrap .meta .right .meta .apps-link .btn:last-child .txt .lbl,
.dark-mode .our-app .wrap .meta .right .meta .apps-link .btn:last-child .txt .name,
.dark-mode .live-autions .wraps .live-autions-nft .blk .card .meta .nft-rating .r-num .lbl,
.dark-mode .explore-sec .wraps .explore-sec-nft .card .meta .nft-numb,
.dark-mode .explore-sec .wraps .explore-sec-nft .card .meta .nft-rating .r-num .lbl,
.dark-mode .buy-sell .wrap .buy-sell-card-block .card .meta .card-tag,
.dark-mode .buy-sell .wrap .buy-sell-card-block .card .meta .card-desc {
  color: #fff;
}
.dark-mode .exchange-page .wrap .content-block .right .right-sec-filter .h-right .filter .select * {
  color: #000 !important;
}
.dark-mode .wallet-connect {
  background: #1f1f1f;
}
.dark-mode .wallet-connect .ico.flex.aic.jc.pointer svg path {
  stroke: #fff;
}
.dark-mode .swap-page {
  background: #1f1f1f;
}
.dark-mode .swap-page .wrap .swap-block .swap-cards .card {
  background: rgb(31 31 31);
}
.dark-mode .swap-page .wrap .swap-block .swap-cards .form-control,
.dark-mode .swap-page .wrap .swap-block .swap-cards .card .field .txt {
  background: #ffffff30;
  color: #fff;
}
.dark-mode .swap-page .wrap .swap-block .swap-cards .form-control * {
  background: #494949;
  color: #fff !important;
}
.dark-mode .filter-sec .left-search .search-box {
  background: #f3f4f861;
}
.dark-mode .filter-sec .left-search .search-box .txt {
  background: transparent;
  color: #ffffffb8;
}
.dark-mode .explore-page .wraps .explore-page-nft .card .nft-img,
.dark-mode .live-autions .wraps .live-autions-nft .blk .card .nft-img {
  border: 1px solid #64c146;
  border-radius: 16px 16px 0 0;
}
.dark-mode .header-cmp {
  background-color: #181717;
}
.dark-mode .explore-page .wraps .explore-page-nft .card .nft-img img,
.dark-mode .live-autions .wraps .live-autions-nft .blk .card .nft-img img,
.dark-mode .explore-sec .wraps .explore-sec-nft .card .nft-img img {
  border-radius: 16px 16px 0 0;
}
.dark-mode .hot-bids .wraps .hot-bids-nft .blk .card .nft-img .img {
  border-radius: 16px 16px 0 0;
}
.dark-mode .hot-bids .wraps .hot-bids-nft .blk .card .nft-img,
.dark-mode .explore-sec .wraps .explore-sec-nft .card .nft-img {
  border: 1px solid #5cc04c;
  border-radius: 16px 16px 0 0;
}
.dark-mode .explore-page .wraps .explore-page-nft .card .meta .nft-numb,
.dark-mode .explore-page .wraps .explore-page-nft .card .meta .nft-rating .r-num .lbl {
  color: #fff;
}

.dark-mode .explore-page .wraps .explore-page-nft .card {
  background: #1f1f1f;
}
.dark-mode .explore-page .wraps .explore-page-nft .card .meta .nft-name .icon svg path,
.dark-mode .hot-bids .wraps .hot-bids-nft .blk .card .meta .nft-name .icon svg path,
.dark-mode .live-autions .wraps .live-autions-nft .blk .card .meta .nft-name .icon svg path,
.explore-sec .wraps .explore-sec-nft .card .meta .nft-name .icon svg path {
  fill: #fff;
}
.dark-mode .hot-bids .wraps .hot-bids-nft .blk .card,
.dark-mode .live-autions .wraps .live-autions-nft .blk .card,
.dark-mode .explore-sec .wraps .explore-sec-nft .card {
  background: #1f1f1f;
}
.dark-mode .explore-page .wraps .explore-page-nft .card .meta,
.dark-mode .hot-bids .wraps .hot-bids-nft .blk .card .meta,
.dark-mode .live-autions .wraps .live-autions-nft .blk .card .meta,
.dark-mode .explore-sec .wraps .explore-sec-nft .card .meta {
  border: 1px solid #80808085;
  border-top: 0;
  border-radius: 0 0 16px 16px;
}
.dark-mode .explore-page .explore-filter .filter-tabs .tab-item {
  border-bottom: 1px solid #80808085;
}
.dark-mode .explore-page .wraps .explore-page-nft .card .meta .nft-rating,
.dark-mode .hot-bids .wraps .hot-bids-nft .blk .card .meta .nft-rating,
.dark-mode .explore-sec .wraps .explore-sec-nft .card .meta .nft-rating {
  margin-bottom: 15px;
}
.dark-mode .explore-page .wraps .explore-page-nft .card .meta .nft-rating .r-like .lbl,
.dark-mode .hot-bids .wraps .hot-bids-nft .blk .card .meta .nft-rating .r-like .lbl,
.dark-mode .explore-sec .wraps .explore-sec-nft .card .meta .nft-rating .r-like .lbl {
  margin-left: 4px;
}
.dark-mode .complete-checkout {
  background: #1f1f1f;
}
.dark-mode .back-btn svg path {
  stroke: #fff;
}
.dark-mode .create-nft-page .wrap .boxs .box .lbl {
  color: #fff;
}
.dark-mode .openordersmobile h1,
.dark-mode .ant-checkbox + span {
  color: #fff;
}
.dark-mode .complete-checkout .wrap .checkout-hdr .icon svg,
.dark-mode .complete-checkout .wrap .checkout-hdr .icon svg path {
  fill: #fff;
  stroke: #fff;
}
.dark-mode .complete-checkout .wrap .checkout-hdr .checkout-tag .lbl,
.dark-mode .complete-checkout .wrap .about-nft .left .right-side .name,
.dark-mode .complete-checkout .wrap .min-tag,
.dark-mode .complete-checkout .wrap .about-nft .right .xrp .xrp-tag,
.dark-mode .complete-checkout .wrap .t-price .lbl,
.dark-mode .nft-detail .wrap .container .right .exploded-box .num,
.dark-mode .nft-detail .wrap .container .right .exploded-box .sale-tag .s-tag,
.dark-mode .nft-detail .wrap .container .right .exploded-box .collector-sec .item .lbl,
.dark-mode .nft-detail .wrap .container .left .property-box .tag,
.dark-mode .nft-detail .wrap .container .left .property-box .property-tag .bx .lbl2,
.dark-mode .nft-detail .wrap .container .right .history-box .tag,
.dark-mode .nft-detail .wrap .container .left .detail-box .tag,
.dark-mode .nft-detail .wrap .container .left .detail-box .blk .item .le .lbl1,
.dark-mode .nft-detail .wrap .container .left .detail-box .blk .item .ri .lbl,
.dark-mode .nft-detail .wrap .container .left .detail-box .blk .item .le .lbl {
  color: #fff;
}
.dark-mode .nft-detail .wrap .container .right .exploded-box .collector-sec .item .social .icon {
  filter: invert(1);
}

.dark-mode .buy-sell .wrap .buy-sell-card-block .card {
  background: #1f1f1f;
  border: 1px solid #80808085;
}
.dark-mode .siderclasses,
.dark-mode .siderheightwidth {
  background-color: #1f1f1f !important;
  border: solid #1f1f1f 1px !important;
}
.dark-mode .ant-menu-sub.ant-menu-inline {
  background-color: #103b64;
}
.dark-mode .ant-table {
  background: #1f1f1f;
}
.dark-mode .ant-table-thead > tr > th,
.dark-mode .nft-detail .wrap .container .right .history-box .blk .item .ri .lbl {
  color: #fff;
}
.dark-mode .tablestyling {
  border: 1px solid #1f1f1f !important;
}
.dark-mode .ant-empty-normal,
.dark-mode .profile-page .wrap .profile-tabs .tab-item,
.dark-mode .u-a .user-activity .tbl .row .row-item {
  color: #fff;
}
.dark-mode .nft-detail .wrap .container .right .exploded-box .collector-sec .item .social svg path {
  fill: #000;
}
.dark-mode .hot-bids .swiper-pointer-events {
  padding-bottom: 30px;
}
.dark-mode .siderheightwidth33 {
  background-color: #1f1f1f;
}
.dark-mode .swapModal-card {
  background: #1f1f1f;
}
.dark-mode .swapModal-card p svg path {
  stroke: #fff;
}
.dark-mode .wallet-connect .wrap .action .avil-wallet .btn .lbl,
.dark-mode .wallet-connect .wrap .action .avil-wallet .btn .lbl small {
  color: #fff;
}
.footer-pg .wrap .left .social .icon:first-child a svg {
  width: 16px;
  height: 15px;
  position: relative;
  left: -1px;
}
.dark-mode .swap-page .wrap .swap-block .swap-cards .card select.form-control * {
  color: #000;
}
.input-search {
  width: 100%;
  max-width: 276px;
  margin: 30px auto;
  input.txt.search {
    border-radius: 8px;
    border: 1px solid #76b91b;
    background: rgba(231, 232, 234, 0.3);
    color: #8f8f8f;
    font-family: Sofia Pro;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    height: 56px;
    max-width: 100%;
    width: 100%;
    padding: 10px;
  }
}
@media screen and (max-width: 991px) {
  .ant-row .ant-col a {
    width: 100%;
    padding: 10px;
  }
}

@media screen and (max-width: 575px) {
  /*.home-p .home-sec {
  min-height: 200px;
  height: auto;
  background-size: contain!important;
  background-position: top right !important;
}*/
}
