.tablescroll {
  overflow: auto !important;
}

.ant-table-cell {
  background-color: transparent !important;
}

.risingdatatablediv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 0px;
  background: #ffffff;
  box-shadow: 0px 27px 65px rgba(65, 62, 62, 0.08);
  border-radius: 20px;
}

.mainbtndivvv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 1px #76b91b;
  background-color: #ffffff;
  border-radius: 2rem;
  padding: 0.2rem;
  width: 28rem;
  box-sizing: border-box;
}

.risingbtn {
  border-radius: 2rem;
  border: solid 1px #76b91b;
  color: white;
  padding: 0.9rem;
  width: 12rem;
  font-size: 1rem;
  background: linear-gradient(
    48.98deg,
    rgba(167, 214, 63, 0.9) -34.08%,
    #39b54a 44.93%,
    #9fd340 126.52%
  );
}

.risingbtn1 {
  border-radius: 2rem;
  border: solid 1px #ffffff;
  color: white;
  padding: 0.9rem;
  width: 12rem;
  font-size: 1rem;
  background: #ffffff;
}

.risingdivvv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}

.downdivv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.downvecc {
  margin-left: 1.5rem;
  margin-top: -0.2rem;
}
