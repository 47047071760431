.maindivofopenorders {
  width: 90% !important;
}

.ant-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  padding: 4px 11px 4px;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #f3f3f3 !important;
  border: 1px solid #d8d8d8 !important;
  border-radius: 3px;
  transition: border 0.3s, box-shadow 0.3s;
}

.greycolormilja2233 {
  color: #8f8f8f;
  font-size: 1.2rem;
}

.displayflexofopenorders {
  display: flex;
  justify-content: space-between;
  width: 90%;
  align-items:flex-end;
}

.backgroundkiyakian {
  background: #f3f3f3 !important;
  border: 1px solid #d8d8d8 !important;
}

.searchbuttoncolor {
  background-color: #4b9a2a;
  color: #fff;
  padding: 1rem;
  border: none;
  font-size: 1.4rem;
  border-radius: 1rem;
}

.slashdiv {
  font-size: 1.8rem;
  margin-top: 3rem;
}

.allbuttondiv {
  margin-top: 2.8rem;
}

.marginoftoptable {
  margin-top: 2rem;
  width: 90%;
}

.tablestyling {
  border: 1px solid #d8d8d8 !important;
  border-radius: 1rem;
}

.notedowndiv {
  border: 1px solid #d8d8d8 !important;
  border-radius: 5px;
  width: 90%;
  margin-top: 9rem;
  background-color: #fff;
  padding: 1rem;
  display: flex;
  font-size: 1.2rem;
  height: auto !important;
}

@media (max-width: 2560px) {
  .openordersmobile {
    display: none !important;
  }
}
@media  screen and (max-width: 1440px) {
  .marginoftoptable, .maindivofopenorders22{width:100%;}
}
@media (max-width: 1200px) {
  .tablestyling {
    border: 1px solid #d8d8d8 !important;
    border-radius: 1rem;
    width: 100%;
    overflow: auto;
  }
}
@media (max-width: 1024px) {
  .displayflexofopenorders{
    width:100% !important;
  }
  .marginoftoptable{
    width:100% !important;
  }
}

@media (max-width: 768px) {
  .openordersmobile {
    display: flex !important;
    justify-content: space-between;
  }

  .openorders1122 {
    display: none !important;
  }

  .displayflexofopenorders {
    display: none !important;
  }

  .displayflexofmobileviwwww {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }

  .searchbuttonasasa {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  .searchbuttoncolor {
    background-color: #4b9a2a;
    color: #fff;

    border: none;
    font-size: 1.4rem;
    border-radius: 1rem;
  }

  .marginoftoptable {
    margin-top: 2rem;
    width: 100% !important;
  }
}
