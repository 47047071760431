.modalbtn{
    font-size: 15px !important;

}
.disconnect-btn{
    background: linear-gradient(48.98deg, rgba(167, 214, 63, 0.9) -34.08%, #39b54a 44.93%, #9fd340 126.52%);
    color: #fff;
border: none !important;
    border-radius: 15px !important;
    padding: 4px 10px !important;
}
.cancel-btn{
    background: #fff !important;
    color: #39b54a !important;
    border: 1px solid #39b54a !important;
    border-radius: 15px !important;
    padding: 3px 10px !important;
}

/* .modal-header {

    border-bottom: 1px solid #39b54a !important;
  }
.modal-footer {

    border-top: 1px solid #39b54a !important;
  } */
