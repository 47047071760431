.swap-page {
  min-height: 730px;
  background: #f5f5f5;
  padding: 100px;
  @include media(">=phone", "<=991px") {
    padding: 60px 0;
  }
  .wrap {
    .swap-block {
      width: 55%;
      @include media(">=phone", "<=1199px") {
        width: 100%;
      }
      .swap-tab {
        border-bottom: 2px solid #cfd7de;
        .swap-item {
          font-weight: 600;
          font-size: 26px;
          line-height: 24px;
          color: #3f444d;
          padding-bottom: 10px;
          border-bottom: 4px solid#39b54a;
        }
      }
      .swap-cards {
        padding: 50px 0 25px;
        height: 285px;
        @include media(">=phone", "<=991px") {
          flex-direction: column;
          height: 100%;
          height: 500px;
        }

        .card {
          height: 100%;
          width: 100%;
          padding: 20px;
          background: rgba(196, 196, 196, 0.5);
          box-shadow: 0px 27px 65px rgba(65, 62, 62, 0.08);
          border-radius: 16px;
          border: 1px solid #76b91b;
          @include media(">=phone", "<=480px") {
            height: 100%;
          }
          .current-balance {
            font-size: 14px;
            color: #8f8f8f;
          }
          .card-hdr {
            .token-info {
              .icon {
                margin-right: 18px;
                height: 40px;
                width: 40px;
                background: #76b91b;
                border-radius: 50%;
                svg {
                  height: 20px;
                  width: 20px;
                }
              }
              .about-token {
                .lbl {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 19px;
                  color: #3f444d;
                  margin-bottom: 5px;
                }
                .tag {
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 24px;
                  color: #333539;
                }
              }
            }

            .dropDown-box {
              .drop-icon {
                height: 24px;
                width: 24px;
                border-radius: 50%;
                background: #ffffff;
                svg {
                  path {
                    fill: #8f8f8f;
                  }
                }
              }
            }
          }
          .field {
            width: 100%;
            margin-bottom: 8px;
            .txt {
              font-size: 16px;
              background: #ffffff;
              border: 1px solid rgba(216, 219, 224, 0.5);
              border-radius: 8px;
              padding: 8px;
              width: 100%;
              border: 1px solid transparent;
              @include anim;
              &:focus {
                border-color: #39b54a;
              }
              @include media(">=phone", "<=480px") {
                margin-top: 20px;
              }
            }
          }
          select{
            &>option:first-child{
              color:#a3a6af;
            }
          &.form-control{
            font-size: 16px;
              background: #ffffff;
              border: 1px solid rgba(216, 219, 224, 0.5);
              border-radius: 8px;
              padding: 8px;
              width: 100%;
              border: 1px solid transparent;
              @include anim;
              &:focus {
                border-color: #39b54a;
                box-shadow:none;
              }
             
              @include media(">=phone", "<=480px") {
                margin-top: 20px;
              }
          }
        }
        }
        .card-left {
          flex: 1;
          height: 100%;
          @include media(">=phone", "<=991px") {
            width: 100%;
            max-width: 80%;
            flex:unset;
          }
        }
        .card-right {
          flex: 1;
          height: 100%;
          @include media(">=phone", "<=480px") {
            width: 100%;
            max-width:80%;
          }
        }
        .card-center {
          padding: 0 10px;
          height: 100%;
          @include media(">=phone", "<=480px") {
            width: 100%;
            padding: 20px 10px;
          }
          .exchange-icon {
            background: linear-gradient(
              48.98deg,
              rgba(167, 214, 63, 0.9) -34.08%,
              #39b54a 44.93%,
              #9fd340 126.52%
            );
            box-shadow: 0px 0px 4px rgba(118, 185, 27, 0.6);
            height: 45px;
            width: 45px;
            border-radius: 50%;
            svg {
              path {
                stroke: #fff;
              }
            }
          }
        }
      }
      .exchange-box-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .exchange-box {
          width: 25rem;
          padding: 0.6rem 1.5rem;
          background-color: #fff;
          border: 1px solid #39b54a;
          border-radius: 25px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .exchange-box-currency {
            display: flex;
            align-items: center;

            p {
              margin-right: 0.8rem;
              color: #8f8f8f;
            }
            svg {
              path {
                stroke: #8f8f8f !important;
              }
            }
          }
        }
      }
      .action {
        width: 100%;
        .btn {
          //   padding: 12px 20px;
          border-radius: 12px;
          width: 100%;
          font-weight: 600;
          font-size: 18px;
          line-height: 34px;
          color: #ffffff;
          margin-top:40px;
          @include media(">=phone", "<=480px") {
            width: 100%;
            max-width:80%;
            margin:40px auto 0;
          }
        }
      }
    }
  }
}
@media screen and (max-width:991px){
  .form-control{
    font-size: 14px;
  }
}