.colortrykrtyhuwy {
    color: #0cb668 !important;
    width: 100% !important;
  }
  .greycolormilja {
    color: #8f8f8f;
    font-size: small;
  }
  
  .redcolormilja {
    color: red;
  }