// Exchange Page
.exchange-page {
  min-height: 730px;
  background: #e5e5e5;
  padding: 20px 15px;
  @include media(">=phone", "<=991px") {
    align-items: center;
    justify-content: center;
  }
  .wrap {
    @include media(">=phone", "<=991px") {
      padding: 0px 1%;
      width: 100%;
    }
    .content-block {
      margin-top: 26px;
      margin-bottom: 10px;
      @include media(">=phone", "<=991px") {
        flex-direction: column;
      }
      .left {
        flex: 0.4;
        // height: 840px;
        .le-top {
          padding: 8px;
          margin-bottom: 10px;
          height: 470px;
          border-bottom: 10px;
          background: #ffffff;
          border: 1px solid #76b91b;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 12px;
          .search-box {
            background: rgba(239, 240, 244, 0.35);
            border: 1px solid #eaf0f1;
            border-radius: 4px;
            padding: 6px;
            &:focus-within {
              border-color: #76b91b;
            }
            .svg {
              height: 20px;
              width: 20px;
            }
            .txt {
              padding: 0 8px;
              font-size: 18px;
            }
          }
          .token_tabs {
            margin-top: 16px;
            border-bottom: 2px solid #eaf0f1;
            @include media(">=phone", "<=991px") {
              font-size: 18px;
            }
            .item {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 6px 0;
              margin-right: 10px;
              font-weight: 400;
              line-height: 16px;
              color: #14192d;
              cursor: pointer;
              @include anim;
              border-bottom: 3px solid transparent;
              &.active {
                font-weight: 600;
                border-bottom: 3px solid #76b91b;
              }
              &:hover {
                border-bottom: 3px solid #76b91b;
              }
              &:last-child {
                margin-right: 0;
              }
            }
          }
          .token-table {
            height: 100%;
            overflow: auto;
            ::-webkit-scrollbar {
              width: 4px;
            }
            .table-block {
              .tbl-row {
                padding: 8px 0;
                margin-right: 4px;
                &:hover {
                  cursor: pointer;
                  background: #f2f3f5;
                  border-radius: 4px;
                  @include anim;
                }
                &:first-child {
                  padding: 16px 0;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 16px;
                  color: #14192d;
                  background: transparent;
                  border-bottom: 2px solid #f3f3f3;
                }
                .row-item {
                  flex: 1;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 16px;
                  color: #14192d;
                  &.red {
                    color: #db1a1a;
                  }
                  &.green {
                    color: #0cb668;
                  }
                  .name1 {
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 16px;
                    color: #14192d;
                  }
                  .name2 {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    color: #8f8f8f;
                  }
                  &:first-child {
                    flex: 1.4;
                  }
                }
              }
            }
          }
        }
        .le-btm {
          padding: 8px;
          // height: 360px;
          height: 540px;
          background: #ffffff;
          border: 1px solid #76b91b;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 12px;
          .sec-tag {
            font-weight: 600;
            font-size: 20px;
            line-height: 20px;
            color: #14192d;
            padding: 10px 0;
          }
          .token-table {
            height: 100%;
            overflow: auto;
            ::-webkit-scrollbar {
              width: 4px;
            }
            .table-block {
              .tbl-row {
                padding: 8px 0;
                margin-right: 4px;
                &:hover {
                  cursor: pointer;
                  background: #f2f3f5;
                  border-radius: 4px;
                  @include anim;
                }
                &:first-child {
                  padding: 16px 0;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 16px;
                  color: #14192d;
                  background: transparent;
                  border-bottom: 2px solid #f3f3f3;
                }
                .row-item {
                  flex: 1;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 16px;
                  color: #14192d;
                  &.red {
                    color: #db1a1a;
                  }
                  &.green {
                    color: #0cb668;
                  }
                  .name1 {
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 16px;
                    color: #14192d;
                  }
                  .name2 {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    color: #8f8f8f;
                  }
                  &:first-child {
                    flex: 1.4;
                  }
                }
              }
            }
          }
        }
      }
      .center {
        flex: 1.6;
        // height: 840px;
        height: 1024px;
        margin: 0 10px;
        @include media(">=phone", "<=991px") {
          margin: 10px 0;
          height: 100%;
        }
        .center-hdr {
          height: 70px;
          padding: 6px;
          background: #ffffff;
          border: 1px solid #76b91b;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 8px;
          @include media(">=phone", "<=991px") {
            height: 100%;
            align-items: start;
            .icon {
              display: none;
            }
          }
          .content-side {
            flex: 1;
            @include media(">=phone", "<=480px") {
              flex-direction: column;
            }
            .cnt_left {
              margin-right: 8px;
              font-weight: 600;
              line-height: 20px;
              color: #14192d;
              @include media(">=phone", "<=991px") {
                font-size: 14px;
              }
            }
            .cnt_right {
              flex: 1;
              @include media(">=phone", "<=991px") {
                flex-direction: column;
              }
              .item {
                flex: 1;
                @include media(">=phone", "<=480px") {
                  flex-direction: row;
                  justify-content: space-between;
                  margin-bottom: 10px;
                }
                .lbl {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 16px;
                  color: #8f8f8f;
                }
                .val {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 16px;
                  color: #14192d;
                }
              }
            }
          }
        }
        .graph-sec {
          margin: 10px 0;
          // height: 390px;
          height: 560px;
          padding: 8px;
          background: #ffffff;
          border: 1px solid #76b91b;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 8px;
          @include media(">=phone", "<=991px") {
            height: 100%;
          }
          .graph-status {
            .status-g {
              width: 70px;
              height: 25px;
              color: #0cb668;
              font-size: 14px;
              background: rgba(12, 182, 104, 0.08);
              border: 1px solid rgba(12, 182, 104, 0.2);
              border-radius: 4px;
            }
            .status-y {
              width: 70px;
              height: 25px;
              font-size: 14px;
              color: #f3bb38;
              background: rgba(247, 193, 67, 0.08);
              border: 1px solid rgba(247, 193, 67, 0.2);
              border-radius: 4px;
            }
          }
          .graph-status {
            .gp {
              flex: 1;
            }
            .gp-row {
              flex: 0.1;
              .gp-numb {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #000000;
              }
            }
          }
          .graph-numb-btm {
            .item {
              flex: 1;
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: #3f444d;
            }
          }
        }
        .connection-sec {
          height: 380px;
          padding: 6px;
          background: #ffffff;
          border: 1px solid #76b91b;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 12px;
          @include media(">=phone", "<=991px") {
            height: 100%;
          }
          .connects-tabs {
            padding: 0 10px;
            height: 40px;
            width: 100%;
            border-bottom: 2px solid #cfd7de;
            .tabs-item {
              flex: 1;
              margin: 0 12px;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 400;
              font-size: 14px;
              line-height: 19px;
              color: #333539;
              @include anim;
              border-bottom: 3px solid transparent;
              &:hover {
                font-weight: 500;
                font-size: 14px;
                border-bottom: 3px solid #76b91b;
              }
              &.active {
                font-weight: 500;
                font-size: 14px;
                border-bottom: 3px solid #76b91b;
              }
            }
          }
          .content {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 30px;
            padding: 12px 0;
            height: 100%;
            @include media(">=phone", "<=991px") {
              grid-template-columns: repeat(1, 1fr);
            }
            .buy-side {
              .hdr {
                .le {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 19px;
                  color: #0cb668;
                }
                .ri {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 16px;
                  text-align: right;
                  color: #8f8f8f;
                }
              }
              .input-data {
                margin: 14px 0;
                flex: 1;
                .field {
                  background: rgba(239, 240, 244, 0.35);
                  border: 1px solid #eaf0f1;
                  border-radius: 4px;
                  padding: 6px;
                  margin-bottom: 10px;
                  .lbl {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: #8f8f8f;
                  }
                  .txt {
                    padding: 0 8px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    color: #b3b7bb;
                  }
                  .tag {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: #8f8f8f;
                  }
                }
              }
              .horizontal-map {
                .road-map {
                  width: 100%;
                  position: relative;
                  .line {
                    width: 100%;
                    border-bottom: 2px solid #cfd7de;
                  }
                  .dot {
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    background: #8f8f8f;
                    border: 2px solid #8f8f8f;
                    &:first-child {
                      background: #ffffff;
                      border-color: #0cb668;
                    }
                  }
                }
                .number {
                  margin-left: 8px;
                  width: 50px;
                  height: 30px;
                  color: #ffffff;
                  border-radius: 4px;
                  background: #0cb668;
                  position: relative;
                  &::after {
                    right: 0;
                    top: 0%;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border-color: rgba(255, 255, 255, 0);
                    border-right-color: #0cb668;
                    border-width: 10px;
                    margin-top: 5px;
                    margin-right: 40px;
                    z-index: 5;
                  }
                }
              }
              .token-val {
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                color: #8f8f8f;
              }
              .action {
                .btn {
                  width: 80%;
                  font-weight: 600;
                  font-size: 18px;
                  color: #ffffff;
                  background: linear-gradient(48.98deg, rgba(167, 214, 63, 0.9) -34.08%, #39b54a 44.93%, #9fd340 126.52%);
                  border-radius: 8px;
                }
              }
            }
            .sell-side {
              .hdr {
                .le {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 19px;
                  color: #db1a1a;
                }
                .ri {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 16px;
                  text-align: right;
                  color: #8f8f8f;
                }
              }
              .input-data {
                margin: 14px 0;
                flex: 1;
                .field {
                  background: rgba(239, 240, 244, 0.35);
                  border: 1px solid #eaf0f1;
                  border-radius: 4px;
                  padding: 6px;
                  margin-bottom: 10px;
                  .lbl {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: #8f8f8f;
                  }
                  .txt {
                    padding: 0 8px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    color: #b3b7bb;
                  }
                  .tag {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: #8f8f8f;
                  }
                }
              }
              .horizontal-map {
                .road-map {
                  width: 100%;
                  position: relative;
                  .line {
                    width: 100%;
                    border-bottom: 2px solid #cfd7de;
                  }
                  .dot {
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    background: #8f8f8f;
                    border: 2px solid #8f8f8f;
                    &:first-child {
                      background: #ffffff;
                      border-color: #db1a1a;
                    }
                  }
                }
                .number {
                  margin-left: 8px;
                  width: 50px;
                  height: 30px;
                  color: #ffffff;
                  border-radius: 4px;
                  background: #db1a1a;
                  position: relative;
                  &::after {
                    right: 0;
                    top: 0%;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border-color: rgba(255, 255, 255, 0);
                    border-right-color: #db1a1a;
                    border-width: 10px;
                    margin-top: 5px;
                    margin-right: 40px;
                    z-index: 5;
                  }
                }
              }
              .token-val {
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                color: #8f8f8f;
              }
              .action {
                .btn {
                  width: 80%;
                  font-weight: 600;
                  font-size: 18px;
                  color: #ffffff;
                  background: linear-gradient(48.98deg, rgba(167, 214, 63, 0.9) -34.08%, #39b54a 44.93%, #9fd340 126.52%);
                  border-radius: 8px;
                }
              }
            }
          }
        }
      }
      .right {
        flex: 0.4;
        padding-bottom: 6px;
        // height: 840px;
        height: 1024px;
        background: #ffffff;
        border: 1px solid #76b91b;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 12px;
        .right-sec-filter {
          padding: 8px;
          .h-left {
            .icon {
              padding: 3px;
              margin-right: 6px;
              background: #cfd7de;
              border-radius: 4px;
            }
          }
          .h-right {
            .filter {
              .lbl {
                margin-right: 6px;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #8f8f8f;
              }
              .select {
                font-size: 11px !important;
                .css-319lph-ValueContainer {
                  padding: 2px;
                }
              }
            }
          }
        }
        .token-table {
          height: 100%;
          padding: 6px;
          overflow: auto;
          ::-webkit-scrollbar {
            width: 4px;
          }
          .table-block {
            .tbl-row {
              padding: 8px 0;
              margin-right: 4px;
              &:hover {
                cursor: pointer;
                background: #f2f3f5;
                border-radius: 4px;
                @include anim;
              }
              &:first-child {
                padding: 14px 0;
                font-weight: 500;
                font-size: 16px;
                line-height: 16px;
                color: #14192d;
                cursor: default;
                background: transparent;
                border-top: 2px solid #f3f3f3;
                border-bottom: 2px solid #f3f3f3;
              }
              .row-item {
                flex: 1;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: #14192d;
                &.red {
                  color: #db1a1a;
                }
                &.green {
                  color: #0cb668;
                }
                .name1 {
                  font-weight: 400;
                  font-size: 10px;
                  line-height: 16px;
                  color: #14192d;
                }
                .name2 {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 16px;
                  color: #8f8f8f;
                }
                &:first-child {
                  flex: 1.4;
                }
              }
            }
          }
        }
      }
    }
    .orders-sec {
      height: 280px;
      padding: 6px;
      background: #ffffff;
      border: 1px solid #76b91b;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
      margin-bottom: 20px;
      @include media(">=phone", "<=991px") {
        height: 100%;
      }
      .tabs-sec {
        border-bottom: 1px solid #cfd7de;
        .i-tab {
          cursor: pointer;
          font-weight: 400;
          font-size: 16px;
          line-height: 25px;
          color: #333539;
          padding: 10px 10px;
          margin: 0 20px;
          border-bottom: 3px solid transparent;
          @include anim;
          @include media(">=phone", "<=991px") {
            font-size: 11px;
            line-height: 12px;
          }
          &.active {
            font-weight: 600;
            border-bottom: 3px solid #76b91b;
          }
        }
      }
      .table-block {
        @include media(">=phone", "<=991px") {
          overflow: auto;
          ::-webkit-scrollbar {
            width: 4px;
          }
          ::-webkit-scrollbar-button {
            /* 2 */
          }
          ::-webkit-scrollbar-track {
          }
          ::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.5);
            border-radius: 15px;
          }
          ::-webkit-scrollbar-thumb:window-inactive {
            background: rgba(0, 0, 0, 0.15);
          }
        }
        .tbl-sec {
          width: 100%;
          @include media(">=phone", "<=991px") {
            width: 1700px;
            ::-webkit-scrollbar {
              width: 4px;
            }
            ::-webkit-scrollbar-button {
              /* 2 */
            }
            ::-webkit-scrollbar-track {
            }
            ::-webkit-scrollbar-thumb {
              background: rgba(0, 0, 0, 0.5);
              border-radius: 15px;
            }
            ::-webkit-scrollbar-thumb:window-inactive {
              background: rgba(0, 0, 0, 0.15);
            }
          }
          .tbl-row {
            padding: 12px;
            width: 100%;
            .tbl-col {
              display: flex;
              flex: 1;
              font-weight: 300;
              font-size: 10px;
              line-height: 19px;
              color: #000000;
              @include media(">=phone", "<=991px") {
                width: 140px;
              }
              &.red {
                color: #ff0000;
              }
              &.green {
                color: #39b54a;
              }
              &.blue {
                color: #1200df;
              }
            }
            &:first-child {
              .tbl-col {
                font-weight: 500;
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }
}
@include media(">=phone", "<=991px") {
  .no-result {
    font-size: 16px;
}
}